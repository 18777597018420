import { useDispatch, useSelector } from "react-redux";
import top_growth_logo from "../images/top-growth-logo-1.png";
import Newslist from "./Forms/Newslist";
import { createSubscribe } from "../Store/Slicers/Newslist/subscribeSlicer";
import { useContext, useState } from "react";
import { Context } from "../App";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function MainNewsList() {
  const [language, setlanguage] = useContext(Context);

  const { loading: subscribeLoading, error: subscribeError } = useSelector(
    (s) => s.subscribeSlicer
  );
  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [message, setMessage] = useState("");

  const timeOut = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  const handleSubmitt = (e) => {
    e.preventDefault();
    setMessage("");
    dispatch(createSubscribe(email)).then((res) => {
      if (res.payload.message === "Subscribe Successfully") {
        setMessage(res.payload.message);
        setemail("");
        timeOut();
      } else {
        if (res.payload.message === "Invaild User Data") {
          console.log(res.payload.message);
          console.log(res.payload.data[0].msg);
          setMessage(res.payload.data[0].msg);
        } else {
          setMessage(res.payload.message);
        }
        // timeOut();
      }
    });
  };
  return (
    <>
      <section className="tf-section section-facts">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="facts-content">
                <h2 className="heading">
                  {language === "en" ? (
                    <>
                      {/* Supporting <span>Top Growth</span> <br />
                      and  */}
                      join our community
                    </>
                  ) : (
                    <>
                      {/* ادعمنا
                      <span>Top Growth</span> <br />
                      وانضم لمجتمعنا */}
                      انضم لمجتمعنا
                    </>
                  )}
                </h2>
                <div className="wow flash" data-wow-delay="400ms">
                  <div className="form-contact">
                    <form onSubmit={handleSubmitt}>
                      <div className="form-group">
                        <input
                          type="email"
                          tabIndex={3}
                          id="email"
                          name="email"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          className="wpcf7-form-control"
                          placeholder={
                            language === "en"
                              ? "Enter Email.."
                              : "ادخل البريد الإلكتروني"
                          }
                          required
                        />
                        {subscribeLoading ? (
                          <>
                            <button
                              className="btn-main wow flash"
                              data-wow-delay="400ms"
                            >
                              <Spinner animation="border" variant="dark" />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit "
                              className="btn-main wow flash"
                              data-wow-delay="400ms"
                            >
                              <i className="fa-solid fa-paper-plane" />
                            </button>
                          </>
                        )}
                      </div>
                      {message && (
                        <>
                          {message === "Subscribe successfully" ? (
                            <>
                              <div className="form-group mt-2">
                                <div
                                  className="alert alert-success"
                                  role="alert"
                                >
                                  {language === "en" ? (
                                    <p>Subscribed successfully</p>
                                  ) : (
                                    <p>تم الاشتراك بنجاح</p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {message === "Email Already Exist" ? (
                                <>
                                  <div className="form-group mt-2">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {language === "en" ? (
                                        <p>Somethig Happen , try again later</p>
                                      ) : (
                                        <p>حدث خطاء , حاول مرة اخري لاحقا</p>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="form-group mt-2">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {language === "en" ? (
                                        <p>Email Already Exist</p>
                                      ) : (
                                        <p>
                                          تم الاشتراك بهذا البريد الاكتروني من
                                          قبل
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
