import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getServiceByIdApi = createAsyncThunk("service/getById", async (serviceId) => {
  const request = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}/api/user/services/getServiceById/${serviceId}`,
    {
      method: "GET",
    }
  )
    .then((responce) => responce.json())
    .then((res) => res)
    .catch((error) => error);

  return request;
});

const getSingleServicesByIdSlicer = createSlice({
  name: "getAllServices",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceByIdApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServiceByIdApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message ===  "Data Fetched successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(getServiceByIdApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});


export default getSingleServicesByIdSlicer.reducer;