import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createSubscribe = createAsyncThunk(
  "subscribe/create",
  async (subscribe) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: subscribe,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/newslist/subscribe`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => err);

    return request;
  }
);

const subscribeSlicer = createSlice({
  name: "subscribe",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: (builder) => {
    builder.addCase(createSubscribe.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSubscribe.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(createSubscribe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default subscribeSlicer.reducer;
