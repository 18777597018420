import { Alert } from "react-bootstrap";

export const SpanAlert = ({color,message}) => {
  return (
    <>
      <p className="p-4" style={{ color: `var(--bs-${color})` }}>
      {message}
      </p>
    </>
  );
};

export default function CustomAlert({ variant, message }) {
  return (
    <>
      <Alert variant={variant}>{message}</Alert>
    </>
  );
}
