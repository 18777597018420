import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCarrersApi = createAsyncThunk("carrers/getAll", async () => {
  const request = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}/api/user/carrers/`,
    {
      method: "GET",
    }
  )
    .then((responce) => responce.json())
    .then((res) => res)
    .catch((error) => error);

  return request;
});

const getAllCarrersSlicer = createSlice({
  name: "getAllCarrers",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getCarrersApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCarrersApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data fetched successfully") {
        state.data = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(getCarrersApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});


export default getAllCarrersSlicer.reducer;