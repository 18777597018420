import { useEffect } from "react";
import About from "../components/AboutComponents/About";
import Footer from "../components/Footer";
import ContactForm from "../components/Forms/ContactForm";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import ScrollUp from "../components/ScrollUp";
import WhatsappBtn from "../components/WhatsappBtn";
import Wrapper from "../components/Wrapper";

export default function ContactPage() {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <>
      {/* <PageTitle title="Contact Us" /> */}
      {/* <About /> */}
      <ContactForm />
    </>
  );
}
