import { Link, redirect, useNavigate } from "react-router-dom";
import { serviceDatail } from "../../Services/services-details";
import IMG from "../IMG";

export default function CradPortfilo(props) {
  const navigate = useNavigate();
  console.log(props.data._id);

  const handelClick = () => {
    navigate(`/portfolio/${props.data._id}`);
  };

  return (
    <>
      <div className="project-item">
        <div className="inner">
          <div className="boxitem">
            <div className="imgbox" onClick={handelClick}>
              {/* <img
                src={`${process.env.REACT_APP_PUBLIC_API}/${props.data.cover_image}`}
                alt={props.textData.name}
              /> */}
              <IMG url={props.data.cover_image} title={props.textData.name} />

              {/* <img
                className="light"
                src={`${process.env.REACT_APP_PUBLIC_API}/${props.data.image}`}
                alt={props.textData.name}
              /> */}
            </div>
            <div className="box-item-content">
              <div className="content-box" onClick={handelClick}>
                <h5
                  id="services-title-home"
                  className=" title text-center"
                  style={{
                    width: "100%",
                    backgroundColor: "#333",
                    color: "#fff",
                    padding: "10px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  <Link name={props.textData.name} to={"/portfolio"}>
                    {props.textData.name}
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
