import CustomSpinner from "./CustomSpinner";

export default function HomeLoader() {
  return (
    <>
    <section className="custom-loader">

      <div className="loadingio-spinner-dual-ring-x0spjb8qpv">
        <div className="ldio-c7qsru5rz7">
          <div />
          <div>
            <div />
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
