import { Link } from "react-router-dom";

export default function Btn(props) {
   

    return <>
          <div className="btn-main wow fadeInLeft" data-wow-delay="400ms">
          <Link to={props.moveTo}>
            <span className="more">{props.title}
              <span className="icon"><i className="fas fa-plus" /></span>
            </span>
          </Link>
        </div>
    </>
};
