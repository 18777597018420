import { useDispatch, useSelector } from "react-redux";
import Btn from "../Btn";
import CradPortfilo from "./CradPortfilo";
import { useEffect, useState } from "react";
import CustomSpinner from "../CustomSpinner";
import { Context } from "../../App";
import { useContext } from "react";
import { Col } from "react-bootstrap";
import Error from "../Error";
import { getServicewithprojectsApi } from "../../Store/Slicers/CServices/getAllServicesSlicerWithProjects";

export default function Portfolio({ data }) {
  const [language, setlanguage] = useContext(Context);

  const { loading, error } = useSelector(
    (s) => s.getAllServicesSlicerWithProjects
  );
  const dispatch = useDispatch();
  // const [services, setServices] = useState([]);

  // const getAllServices = () => {
  //   dispatch(getServicewithprojectsApi()).then((res) => {
  //     if (res.payload.message == "Data Fetched successfully") {
  //       setServices(res.payload.data);
  //       console.log(res.payload.data);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   getAllServices();
  // }, []);

  const ArabicPortfolio = () => {
    return (
      <>
        <section
          className="tf-section section-portfolio style-2"
          id="portfoilo-home"
        >
          <div className="container">
            <div className="row padding-bottom-60">
              <div className="col-md-8 no-padding">
                <div className="heading-top wow fadeInDown custom-services-style">
                  <h3 className="title">الخدمات</h3>
                  <h4 className="sup-title">
                    خدماتنا مرضية
                    <br />
                    وعملائنا مرضيوون{" "}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row themesflat-portfolio ">
              {data && (
                <>
                  {data.map((data, index) => {
                    // if(data.is_work) {
                      return (
                        <Col lg={6} md={6} className="mt-3" key={index}>
                          <CradPortfilo
                            // key={index}
                            data={data}
                            textData={data.ar}
                          />
                        </Col>
                      )
                    // }
                  })}
                </>
              )}
            </div>
          </div>
          <div className="view-portfolio">
            <Btn title="مشاهدة كل المشاريع" moveTo="/portfolio" />
          </div>
        </section>
      </>
    );
  };
  const EnglishPortfolio = () => {
    return (
      <>
        <section
          className="tf-section section-portfolio style-2"
          id="portfoilo-home"
        >
          <div className="container">
            <div className="row padding-bottom-60">
              <div className="col-md-8 no-padding">
                <div className="heading-top wow fadeInDown custom-services-style">
                  <h3 className="title">Our Works</h3>
                  <h4 className="sup-title">
                    An agency is a business
                    <br />
                    that provides specific Portfolio.
                  </h4>
                </div>
              </div>
            </div>
            <div className="row themesflat-portfolio ">
              {data && (
                <>
                  {data.map((data, index) => {
                    // if(data.is_work) {
                      return (
                        <Col lg={6} md={6} className="mt-3" key={index}>
                          <CradPortfilo
                            // key={index}
                            data={data}
                            textData={data.en}
                          />
                        </Col>
                      )
                    // }
                  })}
                </>
              )}
            </div>
          </div>
          <div className="view-portfolio">
            <Btn title="VIEW ALL PORTFOLIO" moveTo="/portfolio" />
          </div>
        </section>
      </>
    );
  };

  return <>{language === "en" ? <EnglishPortfolio /> : <ArabicPortfolio />}</>;
}

// {loading ? (
//   <>
//     <section className="tf-section">
//       <div className="container">
//         <div className="row d-flex justify-content-center gap-1">
//           <CustomSpinner animation={"border"} />
//         </div>
//       </div>
//     </section>
//   </>
// ) : (
//   <>
//     {error ? (
//       <>
//         <Error />
//       </>
//     ) : (
//       <>
//         {data && (
//           <>
//             {data.length > 0 ? (
//               <>
//                 {language === "en" ? (
//                   <EnglishPortfolio />
//                 ) : (
//                   <ArabicPortfolio />
//                 )}
//               </>
//             ) : (
//               <>
//                 <section className="tf-section ">
//                   <div className="container">
//                     <div className="row  text-center">
//                       {language === "en" ? (
//                         <h3>no services add yet</h3>
//                       ) : (
//                         <h3>لا توجد خدمات متاحة الان</h3>
//                       )}
//                     </div>
//                   </div>
//                 </section>
//               </>
//             )}
//           </>
//         )}
//       </>
//     )}
//   </>
// )}
