import { useEffect } from "react";
import Error from "../components/Error";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Wrapper from "../components/Wrapper";
import { Context } from "../App";
import { useContext } from "react";
export default function ErrorPage() {
  const [language, setlanguage] = useContext(Context);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <>
     {/* {language === "en" ?  <PageTitle
        title="Page Not Found"
      /> : <PageTitle
        title="الصفحة غير موجودة"
      />} */}
    
      <Error />
    </>
  );
}
