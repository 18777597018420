import { useEffect } from "react";
import PortfoliloPage from "../components/PortfiliosComponetns/PortfoliloPage";

export default function PortfilioPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PortfoliloPage />
    </>
  );
}
