import { RiTeamFill } from "react-icons/ri";

import aboutMain from "../../images/page/about-img.webp";
import { Context } from "../../App";
import { useContext, useState } from "react";
import Btn from "../Btn";

export default function AboutSection() {
  const [language, setlanguage] = useContext(Context);

  const ArabicAboutMain = () => {
    const [titleH5, setTitleH5] = useState(0);
    const [textDiv, setTextDiv] = useState(0);

    const handleAboutSection = (index) => {
      setTitleH5(index);
      setTextDiv(index);
    };
    return (
      <>
        <section className="tf-section section-about section-about-new-style padding-top-200">
          <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-md-12 col-lg-5">
                <div
                  className="intro-content wow fadeInLeft"
                  style={{ "padding-top": "30px" }}
                >
                  <h4 className="sup-title">
                    <span>اخبارنا</span>
                  </h4>
                  <h3 className="title">خبرتنا تنحصر في هذة الكلمات</h3>
                  <div className="about-titles">
                    <h5
                      className={`description ${
                        titleH5 === 0 ? `active` : ``
                      } `}
                      onClick={() => handleAboutSection(0)}
                    >
                      رؤيتنا
                    </h5>
                    <h5
                      className={`description ${
                        titleH5 === 1 ? `active` : ``
                      } `}
                      onClick={() => handleAboutSection(1)}
                    >
                      مهمتنا
                    </h5>
                    <h5
                      className={`description ${
                        titleH5 === 2 ? `active` : ``
                      } `}
                      onClick={() => handleAboutSection(2)}
                    >
                      اهدافنا
                    </h5>
                  </div>
                  <p
                    className={`text about-pharagraph ${
                      textDiv === 0 ? `active` : ``
                    } `}
                  >


                    <h5> رؤيتنا </h5>
                    <p style={{textAlign: "start"}}>
                    في توب جروث، نتصور مستقبلاً حيث تزدهر الشركات في العصر الرقمي من خلال الابتكار الاستراتيجي والحلول التحولية. رؤيتنا هي تمكين المؤسسات على المستوى العالمي بخبرتنا الرائدة في التسويق الرقمي وتطوير البرمجيات، ووضع معايير جديدة للنمو والاستدامة.
                    </p>
                    <ul className="custom-icon-list">
                      <li style={{textAlign: "start"}}>مدفوعون بالنزاهة
                      </li>
                      <li style={{textAlign: "start"}}>مدفوعون بالابتكار
                      </li>
                      <li style={{textAlign: "start"}}>مركزون على نجاح العميل</li>
                      <li style={{textAlign: "start"}}>ملتزمون بالتميز</li>
                    </ul>
                  </p>
                  <p
                    className={`text about-pharagraph ${
                      textDiv === 1 ? `active` : ``
                    } `}
                  >
                    <h5>مهمتنا</h5>
                    <p className="" style={{textAlign: "start"}}>في توب جروث، نسعى إلى تمكين الشركات من خلال حلول رقمية شاملة تعزز النمو والنجاح في عالم الإنترنت. بالاستفادة من عقد من الخبرة وفريق متفانٍ من المحترفين المهرة، نحن ملتزمون بتقديم استراتيجيات مبتكرة ومؤثرة مصممة خصيصًا لتلبية احتياجات عملائنا الفريدة.</p>
                    <ul className="custom-icon-list">
                      <li style={{textAlign: "start"}}>التركيز على العميل</li>
                      <li style={{textAlign: "start"}}>التميز في التنفيذ</li>
                      <li style={{textAlign: "start"}}>التحسين المستمر</li>
                      <li style={{textAlign: "start"}}>القرارات المستندة إلى البيانات</li>
                      <li style={{textAlign: "start"}}>الشراكات التعاونية</li>
                    </ul>
                  </p>
                  <p
                    className={`text about-pharagraph ${
                      textDiv === 2 ? `active` : ``
                    } `}
                  >
                    <h5>قيمنا</h5>
                    تعتبر قيمنا الأساسية دافعًا لكل ما نقوم به في توب جروث:
                    <ul className="custom-icon-list">
                      <li style={{textAlign: "start"}}>النزاهة: الصدق والشفافية في جميع أفعالنا.
                      </li>
                      <li style={{textAlign: "start"}}>الابتكار: السعي المستمر وراء الحلول الإبداعية.
                      </li>
                      <li style={{textAlign: "start"}}>تركيز العملاء: الأولوية لاحتياجات عملائنا.
                      </li>
                      <li style={{textAlign: "start"}}>التعاون: العمل الجماعي والتواصل المفتوح.</li>
                    </ul>
                  </p>
                </div>
              </div>

              <div className="col-md-12 col-lg-7 wow fadeInRight">
                <div className="image-about up-down">
                  <img
                    src={aboutMain}
                    width={620}
                    alt="topgrowth about image "
                  />
                </div>
              </div>
            </div>

          </div>
        </section>
      </>
    );
  };
  const EnglishAboutMain = () => {
    const [titleH5, setTitleH5] = useState(0);
    const [textDiv, setTextDiv] = useState(0);

    const handleAboutSection = (index) => {
      setTitleH5(index);
      setTextDiv(index);
    };

    return (
      <>
        <section className="tf-section section-about section-about-new-style padding-top-200">
          <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-md-12 col-lg-5">
                <div
                  className="intro-content wow fadeInLeft"
                  style={{ "padding-top": "30px" }}
                >
                  <h4 className="sup-title">
                    <span>About Us</span>
                  </h4>
                  <h3 className="title">
                    OUR 10 YEARS WORKING EXPERIENCE DESIGN
                  </h3>
                  <div className="about-titles">
                    <h5
                      className={`description ${
                        titleH5 === 0 ? `active` : ``
                      } `}
                      onClick={() => handleAboutSection(0)}
                    >
                      OUR VISION
                    </h5>
                    <h5
                      className={`description ${
                        titleH5 === 1 ? `active` : ``
                      } `}
                      onClick={() => handleAboutSection(1)}
                    >
                      OUR MISSION
                    </h5>
                    <h5
                      className={`description ${
                        titleH5 === 2 ? `active` : ``
                      } `}
                      onClick={() => handleAboutSection(2)}
                    >
                      OUR VALUES
                    </h5>
                  </div>
                  <p
                    className={`text about-pharagraph ${
                      textDiv === 0 ? `active` : ``
                    } `}
                  >
                    <h5>Our Vision</h5>
                    <p style={{textAlign: "start"}}>At Top Growth, we envision a future where businesses thrive in the digital age through strategic innovation and transformative solutions. Our vision is to empower organizations globally with cutting-edge digital marketing and software expertise, setting new benchmarks for growth and sustainability.</p>
                    <ul className="custom-icon-list">
                      <li style={{textAlign: "start"}}>Driven by Integrity
                      </li>
                      <li style={{textAlign: "start"}}>Powered by Innovation
                      </li>
                      <li style={{textAlign: "start"}}>Centered on Client Success
                      </li>
                      <li style={{textAlign: "start"}}>Committed to Excellence</li>
                    </ul>
                  </p>
                  <p
                    className={`text about-pharagraph ${
                      textDiv === 1 ? `active` : ``
                    } `}
                  >
                    <h5> Our Mission</h5>
                    <p style={{textAlign: "start"}}>
                    At Top Growth, our mission is to empower businesses with comprehensive digital solutions that drive growth and success in the online world. Leveraging a decade of experience and a dedicated team of skilled professionals, we are committed to delivering innovative and impactful strategies tailored to our clients' unique needs.
                    </p>
                    <ul className="custom-icon-list">
                      <li style={{textAlign: "start"}}>Client-Centric Approach
                      </li>
                      <li style={{textAlign: "start"}}>Excellence in Execution
                      </li>
                      <li>Continuous Improvement</li>
                      <li style={{textAlign: "start"}}>Data-Driven Decisions
                      </li>
                      <li style={{textAlign: "start"}}>Collaborative Partnerships</li>
                    </ul>
                  </p>
                  <p
                    className={`text about-pharagraph ${
                      textDiv === 2 ? `active` : ``
                    } `}
                  >
                    <h5> Our Values</h5>
                    Our core values drive everything we do at Top Growth:
                    <ul className="custom-icon-list">
                      <li style={{textAlign: "start"}}>Integrity: Honesty and transparency in all our actions.
                      </li>
                      <li style={{textAlign: "start"}}>Innovation: Continuous pursuit of creative solutions.
                      </li>
                      <li style={{textAlign: "start"}}>Customer Focus: Prioritizing our clients' needs.
                      </li>
                      <li style={{textAlign: "start"}}>Collaboration: Teamwork and open communication.</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div
                className="col-md-12 col-lg-7 wow fadeInRight"
                data-wow-delay="400ms"
              >
                <div className="image-about up-down">
                  <img src={aboutMain} alt="TopGrowth About Main Image" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return <>{language === "en" ? <EnglishAboutMain /> : <ArabicAboutMain />}</>;
}
