import Carousel from "react-bootstrap/Carousel";

import slider1 from "../../../images/slider/slider1.webp";
import slider2 from "../../../images/slider/slider2.webp";
import slider3 from "../../../images/slider/slider3.webp";

import mobile from "../../../images/slider/1.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Col } from "react-bootstrap";
import { Context } from "../../../App";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
export default function DataBootstrapHero({ data }) {
  console.log("this is home data ", data);
  const [language, setlanguage] = useContext(Context);




  const ArabicHero = () => {
    useEffect(() => {
      const prevCarsoelPointer = document.querySelector(".section-custom-slider .carousel .carousel-control-prev");
      const nextCarsoelPointer = document.querySelector(".section-custom-slider .carousel .carousel-control-next");
      if(data && data.length <= 1 && prevCarsoelPointer && nextCarsoelPointer) {
        prevCarsoelPointer.style.display = "none";
        nextCarsoelPointer.style.display = "none";
      }
    },[data])
    return (
      <>
        <section className="section-custom-slider" dir="rtl" >
          <Carousel indicators={false} variant="danger">
            {data.map((item) => (
              <Carousel.Item>
                {item.image ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_API}/${item.image}`}
                      className="desktop  w-100"
                      alt="desktop slider1"
                    />
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_API}/${item.image}`}
                      className="mobile  w-100"
                      alt="mobile slider1"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={slider1}
                      className="desktop  w-100"
                      alt="desktop slider1"
                    />
                    <img
                      src={mobile}
                      className="mobile  w-100"
                      alt="mobile slider1"
                    />
                  </>
                )}
                <div className="custom-caption container">
                  <div className="wow slideInLeft" data-wow-duration="2s">
                    {/* <h3>الخدمة المقدمة هي</h3> */}
                  </div>
                  <div className="wow slideInLeft" data-wow-duration="2s">
                    <h2 style={{transform: "translateY(-20px)"}}>{item.ar.name}</h2>
                  </div>
                  {/* <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>الالكتروني منا</h2>
                </div> */}
                  <div className="wow flash" data-wow-duration="2s">
                    <h5>{item.ar.description}</h5>
                  </div>
                  <div className="wow flash" data-wow-duration="2s">
                    <div className="btn-main ">
                      <Link to={"/about"}>
                        <span class="more">
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                          معرفة المذيد
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}

            {/* <Carousel.Item>
            <img
                src={slider2}
                className="desktop  w-100"
                alt="desktop slider1"
              />
              <img
                src={mobile}
                className="mobile  w-100"
                alt="mobile slider1"
              />
              <div className="custom-caption container">
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h3>الخدمة المقدمة هي</h3>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>خدمة التسويق</h2>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>الالكتروني منا</h2>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <h5>
                  اكتشف فعالية مجموعة متنوعة من الاستراتيجيات عبر الإنترنت التي تنفذها Top Growth لتعزيز رؤية أعمالك أو منتجاتك أو خدماتك.
                    <br />
                    تكمن كفاءتنا في الوصول إلى جمهور أوسع وإشراكه، مما يضمن النتائج المثلى لمبادرات التسويق الرقمي الخاصة بك
                  </h5>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <div className="btn-main ">
                    <Link to={"/services"}>
                      <span class="more">
                        <span class="icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        معرفة المذيد
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
            <img
                src={slider3}
                className="desktop  w-100"
                alt="desktop slider3"
              />
              <img
                src={mobile}
                className="mobile  w-100"
                alt="mobile slider1"
              />
              <div className="custom-caption container">
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h3>الخدمة المقدمة هي</h3>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>خدمة التسويق</h2>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>الالكتروني منا</h2>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <h5>
                  نحن متخصصون في الوصول إلى جمهور واسع وإشراكه، مما يضمن النتائج المثلى لمبادرات التسويق الرقمي الخاصة بك
                  </h5>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <div className="btn-main ">
                    <Link to={"/services"}>
                      <span class="more">
                        <span class="icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        معرفة المذيد
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </section>
      </>
    );
  };

  const EnlishHero = () => {
    useEffect(() => {
      const prevCarsoelPointer = document.querySelector(".section-custom-slider .carousel .carousel-control-prev");
      const nextCarsoelPointer = document.querySelector(".section-custom-slider .carousel .carousel-control-next");
      if(data && data.length <= 1 && prevCarsoelPointer && nextCarsoelPointer) {
        prevCarsoelPointer.style.display = "none";
        nextCarsoelPointer.style.display = "none";
      }
    },[data])
    return (
      <>
        <section className="section-custom-slider">
          <Carousel indicators={false} variant="danger">
            {data.map((item) => (
              <Carousel.Item>
              {item.image ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_API}/${item.image}`}
                      className="desktop  w-100"
                      alt="desktop slider1"
                    />
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_API}/${item.image}`}
                      className="mobile  w-100"
                      alt="mobile slider1"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={slider1}
                      className="desktop  w-100"
                      alt="desktop slider1"
                    />
                    <img
                      src={mobile}
                      className="mobile  w-100"
                      alt="mobile slider1"
                    />
                  </>
                )}
                <div className="custom-caption container">
                  <div className="wow slideInLeft" data-wow-duration="2s">
                    {/* <h3>CONSIDER TOP GROWTH FOR </h3> */}
                  </div>
                  <div className="wow slideInLeft m-0" data-wow-duration="2s">
                    <h2 style={{transform: "translateY(-20px)"}}>{item.en.name} </h2>
                  </div>
                  {/* <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>MARKETING NEEDS..</h2>
                </div> */}
                  <div className="wow flash" data-wow-duration="2s">
                    <h5>{item.en.description}</h5>
                  </div>
                  <div className="wow flash" data-wow-duration="2s">
                    <div className="btn-main ">
                      <Link to={"/about"}>
                        <span class="more">
                          DISCOVER MORE{" "}
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}

            {/* <Carousel.Item>
              <img
                src={slider2}
                className="desktop  w-100"
                alt="desktop slider2"
              />
              <img
                src={slider3}
                className="mobile  w-100"
                alt="mobile slider3"
              />
              <div className="custom-caption container">
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h3>CONSIDER TOP GROWTH FOR </h3>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>YOUR DIGITAL </h2>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>MARKETING NEEDS..</h2>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <h5>
                  Uncover the effectiveness of a variety of online strategies implemented by Top Growth to enhance the visibility of your business, products, or services.
                    <br />
                    Our proficiency lies in reaching and engaging a wider audience, guaranteeing optimal results for your digital marketing initiatives
                  </h5>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <div className="btn-main ">
                    <Link to={"/services"}>
                      <span class="more">
                        DISCOVER MORE{" "}
                        <span class="icon">
                          <i class="fas fa-plus"></i>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={slider3}
                className="desktop  w-100"
                alt="desktop slider3"
              />
              <img
                src={slider1}
                className="mobile  w-100"
                alt="mobile slider1"
              />{" "}
              <div className="custom-caption container">
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h3>CONSIDER TOP GROWTH FOR </h3>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>YOUR DIGITAL </h2>
                </div>
                <div className="wow slideInLeft" data-wow-duration="2s">
                  <h2>MARKETING NEEDS..</h2>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <h5>
                  We specialize in reaching and engaging a wide audience, ensuring optimal results for your digital marketing initiatives
                    
                  </h5>
                </div>
                <div className="wow flash" data-wow-duration="2s">
                  <div className="btn-main ">
                    <Link to={"/services"}>
                      <span class="more">
                        DISCOVER MORE{" "}
                        <span class="icon">
                          <i class="fas fa-plus"></i>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Carousel.Item> */}
          </Carousel>
        </section>
      </>
    );
  };

  return <>{language === "en" ? <EnlishHero /> : <ArabicHero />}</>;
}
