import Couter from "../components/Couter";

import MainServices from "../components/ServicesCmponents/MainServices";

import Video from "../components/Video";
import Portfolio from "../components/PortfiliosComponetns/Portfolio";

import BootstrapHero from "../jsx/components/home/BootstrapHero";
import { useEffect, useState } from "react";
import WOW from "wowjs";
import { useDispatch, useSelector } from "react-redux";
import { getHomeDataApi } from "../Store/Slicers/Home/getAllHomeDataSlicer";
import CustomSpinner from "../components/CustomSpinner";
import DataBootstrapHero from "../jsx/components/home/DataBootstrapHero";
import HomeLoader from "../components/HomeLoader";
import HomeDataVideo from "../jsx/components/home/HomeDataVideo";

export default function Home(data) {
  console.log("this is data", data.data);
  const [homeData, setHomeData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {data &&
      data.data &&
      data.data.sliders &&
      data.data.sliders.length != 0 ? (
        <DataBootstrapHero data={data.data.sliders} />
      ) : (
        <BootstrapHero />
      )}

      {/* <About /> */}

      {data &&
        data.data &&
        data.data.homeProjects &&
        data.data.homeProjects.length != 0 && (
          // <Portfolio data={data.data.homeProjects && data.data.homeProjects.slice(0,4)} />
          <Portfolio data={data.data.homeProjects && data.data.homeProjects} />
        )}
      {data &&
        data.data &&
        data.data.servises &&
        data.data.servises.length != 0 && <MainServices />}

      {data && data.data && data.data.homeVideo && data.data.homeVideo[0] && (
        <>
          <Couter />
          <HomeDataVideo data={data.data.homeVideo[0]} />
        </>
      )}
    </>
  );
}
