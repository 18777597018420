import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import "react-rev-slider/dist/react-rev-slider.css"
import "./css/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/shortcodes.css";
import "./css/style.css";
import "./css/responsive.css";
// import  './css/jquery.fancybox.css' ;
// import  './css/owl.carousel.css' ;
import "./css/animate.css";
import "./css/icomoon.css";
import "./css/addition.css";
import "./css/portfolio--new-styles.css";

import store from "./Store/index";
import { Provider } from "react-redux";
import App from "./App";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
 
);
