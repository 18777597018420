import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProjectsByIdApi = createAsyncThunk("project/getbyId", async (projectId) => {
  const request = await fetch(
    `${process.env.REACT_APP_PUBLIC_API}/api/user/project/getById/${projectId}`,
    {
      method: "GET",
    }
  )
    .then((responce) => responce.json())
    .then((res) => res)
    .catch((error) => error);

  return request;
});

const getSingleProjectsSlicer = createSlice({
  name: "getAllProject",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectsByIdApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectsByIdApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message == "Data fetched successfully") {
        state.data = action.payload.data;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(getProjectsByIdApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});


export default getSingleProjectsSlicer.reducer;