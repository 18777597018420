import whatsapp from  "../images/icon/whatsapp.png" ;


export default function WhatsappBtn() {
    return <>
        <div className="contact-whatsapp">
        <a href="https://web.whatsapp.com/send?phone=+2001069273070" className="call" target="_blank"
          >
          <img src={whatsapp} alt=""
        />
        </a>
      </div>
        
    </>
};
