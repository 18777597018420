import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCarrersApi } from "../Store/Slicers/Carrers/getAllCarrersSlicer";
import { Context } from "../App";
import { Link } from "react-router-dom";

export default function CareersSections() {
  const [language, setlanguage] = useContext(Context);
  const { loading, error } = useSelector((state) => state.getAllCarrersSlicer);
  const dispatch = useDispatch();
  const [postions, setPostions] = useState([]);

  const getAllPostions = () => {
    dispatch(getCarrersApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setPostions(res.payload.data);
      }
    });
  };

  useEffect(() => {
    getAllPostions();
  }, []);

  return (
    <>
      <section className="tf-section section-counter secyion-carrer style-2 padding-top-150 mt-5" dir="ltr">
        <div className="container">
          {postions && postions.length != 0 && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="heading-top wow fadeInDown">
                    <h4 className="sub-title padding-bottom-20" style={{letterSpacing: language !== "en" && "0"}}>
                      {language === "en" ? (
                        <>CURRENTLY RECRUITING</>
                      ) : (
                        <>الوظائف الحالية المتاحة</>
                      )}
                    </h4>
                    <h3 className="title">
                      {language === "en" ? (
                        <>EXPLORE CAREER OPPORTUNITIES WITH US</>
                      ) : (
                        <>اكتشف الفرص الوظيفية معنا</>
                      )}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row  padding-top-100">
                <div className="col-lg-8 col-md-10 col-sm-12 m-auto">
                  <table className="table cm-table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>
                          {
                            language === "en"
                            ?
                            "Postion"
                            :
                            "الوظيفة"
                          }
                        </th>
                        <th scope="col">
                          {
                            language === "en"
                            ?
                            "Location"
                            :
                            "مكان العمل"
                          }
                        </th>
                        <th scope="col">
                        {
                          language === "en"
                          ?
                          "Type"
                          :
                          "نوع العمل"
                        }
                        </th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="table-group-divider">
                      {postions.map((item, index) => (
                        <tr>
                          <td colSpan={2} className="postions-title">
                            {
                              language === "en"
                              ?
                              item.en.postion
                              :
                              item.ar.postion
                            }
                          </td>
                          <td className="postions-location">
                            {" "}
                            {
                              language === "en"
                              ?
                              item.en.location
                              :
                              item.ar.location
                            }
                          </td>
                          <td className="postions-type"> 
                            {
                              language === "en"
                              ?
                              item.en.type
                              :
                              item.ar.type
                            }
                          </td>
                          <td>
                            <Link to={`/applay-career/${item._id}`} className="btn btn-sm">
                            {
                              language === "en"
                              ?
                              "Apply Now"
                              :
                              "تقديم الان"
                            }
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
