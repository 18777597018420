import CardService from "./CardService";

import headingform from "../../images/page/email.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getServiceApi } from "../../Store/Slicers/CServices/getAllServicesSlicer";
import CustomSpinner from "../CustomSpinner";
import { Context } from "../../App";
import { useContext } from "react";
import Error from "../Error";
import Icons from "../Icons";
import { Link } from "react-router-dom";
import { createSubscribe } from "../../Store/Slicers/Newslist/subscribeSlicer";
import { Spinner } from "react-bootstrap";
import Newslist from "../Forms/Newslist";

export default function MainServices() {
  const [language, setlanguage] = useContext(Context);

  const { loading, error } = useSelector((s) => s.getAllServicesSlicer);
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);

  const getAllServices = () => {
    dispatch(getServiceApi()).then((res) => {
      if (res.payload.message == "Data Fetched successfully") {
        setServices(res.payload.data);
        console.log(res.payload.data);
      }
    });
  };

  useEffect(() => {
    getAllServices();
  }, []);

  const ArabicMainServices = () => {
    const { loading: subscribeLoading, error: subscribeError } = useSelector(
      (s) => s.subscribeSlicer
    );

    const [email, setemail] = useState("");
    const [message, setMessage] = useState("");

    const timeOut = () => {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    };
    const handleSubmitt = (e) => {
      e.preventDefault();
      dispatch(createSubscribe(email)).then((res) => {
        if (res.payload.message === "Subscribe Successfully") {
          setMessage(res.payload.message);
          timeOut();
        } else {
          setMessage(res.payload.message);
          timeOut();
        }
      });
    };

    return (
      <>
        <section className="tf-section section-work style-2 services-section">
          <div className="container">
            <div className="row padding-bottom-70">
              <div className="col-md-8">
                <div className="heading-top wow fadeInDown">
                  <h3 className="title">الخدمات المتاحة</h3>
                  <h4 className="title">
                    شركتنا تقدم بعض
                    <br />
                    الخدمات المطلوبة في سوق العمل{" "}
                  </h4>
                </div>
              </div>
              <hr id="divider" />
            </div>
            <div className="list-box-work row d-flex justify-content-between">
              {services.map((data, index) => (
                <CardService
                  key={index}
                  serviceData={data}
                  textData={data.ar}
                  delay={`${index * 200} ms`}
                />
              ))}
            </div>
            {/* <Newslist /> */}
          </div>
        </section>
      </>
    );
  };

  const EnglishMainServices = () => {
    return (
      <>
        <section className="tf-section section-work style-2 services-section">
          <div className="container">
            <div className="row padding-bottom-70">
              <div className="col-md-8">
                <div className="heading-top wow fadeInDown">
                  <h3 className="title">Our Services</h3>
                  <h4 className="title">
                    An agency is a business
                    <br />
                    that provides specific Portfolio.
                  </h4>
                </div>
              </div>
              <hr id="divider" />
            </div>
            <div className="list-box-work row d-flex justify-content-between">
              {services.map((data, index) => (
                <>
                  <CardService
                    key={index}
                    serviceData={data}
                    textData={data.en}
                    delay={`${index * 2}s`}
                  />
                </>
              ))}
            </div>
            {/* <Newslist /> */}
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <>
          <section className="tf-section">
            <div className="container">
              <div className="row d-flex justify-content-center gap-1">
                <CustomSpinner animation={"border"} />
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {error ? (
            <>
              <Error />
            </>
          ) : (
            <>
              {services.length > 0 ? (
                <>
                  {language === "en" ? (
                    <EnglishMainServices />
                  ) : (
                    <ArabicMainServices />
                  )}
                </>
              ) : (
                <>
                  <section className="tf-section ">
                    <div className="container">
                      <div className="row  text-center">
                        {language === "en" ? (
                          <h3>no services add yet</h3>
                        ) : (
                          <h3>لا توجد خدمات متاحة الان</h3>
                        )}
                      </div>
                    </div>
                  </section>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
