import tempintImge from "../images/page/about-img.webp";

export default function IMG({ url, title }) {
  return (
    <>
      {url && url !== "" ? (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_API}/${url}`}
            alt={title ? title : "img"}
          />
        </>
      ) : (
        <>
          <img src={tempintImge} alt="Top Growth Img" />
        </>
      )}
    </>
  );
}
