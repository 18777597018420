import About from "../components/AboutComponents/About";
import InternshipForm from "../components/Forms/InternshipForm";
import PageTitle from "../components/PageTitle";

import { Context } from "../App";
import { useContext, useEffect } from "react";
import CareersSections from "../components/CareersSections";

export default function InternshipsPage() {
  const [language, setlanguage] = useContext(Context);
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  return (
    <>
      {/* {language === "en" ? (
        <PageTitle title="Internships" />
      ) : (
        <PageTitle title="تدريباتنا المقدمة " />
      )}
      */}
      {/* <About /> */}
      <CareersSections />
      {/* <InternshipForm /> */}
    </>
  );
}
