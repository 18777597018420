import { Link, useLocation } from "react-router-dom";
import { Context } from "../App";

import ar_logo from "../images/logos/ar_logo.png";
import en_logo from "../images/logos/en_logo.png";
import { useContext, useState } from "react";

export default function CustomHeader() {
  const [language, setlanguage] = useContext(Context);
  const [openmenu, setopenMenu] = useState(false);
  const { pathname } = useLocation();

  const changeLanguage = () => {
    setopenMenu(false);
    if (language === "en") {
      setlanguage("ar");
      document.documentElement.setAttribute("dir", "rtl");
      document.documentElement.setAttribute("lang", "ar");
      localStorage.setItem("language", "ar");
    } else {
      setlanguage("en");
      document.documentElement.setAttribute("dir", "ltr");
      document.documentElement.setAttribute("lang", "en");
      localStorage.setItem("language", "en");
    }
  };
  return (
    <>
      <header className={`cm-header ${pathname !== "/" && "fixed"}`}>
        <div className="container">
          <div className="cm-header-content">
            <div className="cm-header-logo">
              <Link to="/">
                <img
                  src={language === "en" ? en_logo : ar_logo}
                  alt="Tpp grpwth logo"
                  />
              </Link>
            </div>
            <nav className="cm-header-nav">
              <div
                className={`mobile-button ${openmenu && "active"} `}
                onClick={() => setopenMenu(!openmenu)}
              >
                <span></span>
              </div>
              <ul className={` ${openmenu && "show"} m-0`}>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => setopenMenu(false)}
                >
                  <Link className="menu-main" to={"/"}>
                    {language == "en" ? "Home" : "الصفحة الرئيسية"}
                  </Link>
                </li>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => setopenMenu(false)}
                >
                  <Link className="menu-main" to={"/about"}>
                    {language == "en" ? "About" : "عنا"}
                  </Link>
                </li>
                {/* <li
                  className="menu-item menu-item-has-children"
                  onClick={() => setopenMenu(false)}
                >
                  <Link className="menu-main" to={"/services"}>
                    {language == "en" ? "Services" : "خدماتنا"}
                  </Link>
                </li> */}
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => setopenMenu(false)}
                >
                  <Link to={"/portfolio"} className="menu-main">
                    {language == "en" ? "Portfolio" : "المشاريع"}
                  </Link>
                </li>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => setopenMenu(false)}
                >
                  <Link className="menu-main" to={"/career"}>
                    {language == "en" ? "Career" : "وظائفنا"}
                  </Link>
                </li>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => setopenMenu(false)}
                >
                  <Link className="menu-main" to={"/contact"}>
                    {language == "en" ? "Let's talk" : "تواصل معنا"}
                  </Link>
                </li>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => changeLanguage()}
                >
                  <Link className="menu-main" to={"#"}>
                    {language == "en" ? "عربي" : "ENGLISH"}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
