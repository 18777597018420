import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getContact = createAsyncThunk(
  "contact/getContact",
  async (contactData) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(contactData);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/student/contact/create_contact_us`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => err);

    return request;
  }
);

const contactSlicer = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getContact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message == "Message sent successfully") {
        state.data = action.payload;
      } else {
        state.error = action.payload.message;
      }
      
    });
    builder.addCase(getContact.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default contactSlicer.reducer;
