import { Link } from "react-router-dom";
import portfoiloImg from "../../images/portfolio/img-box-02.jpg";
import { Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import CustomSpinner from "../CustomSpinner";
import { getProjectsApi } from "../../Store/Slicers/Projects/getAllProjectsSlicer";
import "../../css/custom-portfolio-style.css";
import Error from "../Error";
import { Context } from "../../App";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import tempImage from "../../images/page/about-img.webp";
import IMG from "../IMG";
import { getServiceApi } from "../../Store/Slicers/CServices/getAllServicesSlicer";

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

function FilterProjectsNav({show , onHide , position }) {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow} className="me-2">
        {name}
      </Button> */}
      <Offcanvas show={show} onHide={onHide} placement={position}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}



export default function PortfoliloPage() {
  const [language, setlanguage] = useContext(Context);
  const [showFilterProjects, setShowFilterProjects] = useState();
  
  const { loading, error  , data:projectsData} = useSelector((s) => s.getAllProjectsSlicer);
  const { loading:getServicesLoading  , data:servicesData} = useSelector((s) => s.getAllServicesSlicer);
  const dispatch = useDispatch();
  const [project, setProject] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceFilter, setServiceFilter] = useState("");
  const [allServicesOfAllProjects , setAllServicesOfAllProjects] = useState([]);

  const getAllProdjects = () => {
    dispatch(getProjectsApi()).then((res) => {
      if (res.payload.message == "Data fetched successfully") {
        setProject(res.payload.data);
        console.log(res.payload.data);
        console.log(project);
      }
    });
  };
  const getServices = () => {
    dispatch(getServiceApi()).then((res) => {
      if (res.payload.message == "Data Fetched successfully") {
        console.log(res.payload)
        setServices(res.payload.data);
        console.log(res.payload.data);
        console.log(project);
      }
    });
  };

  useEffect(() => {
    getAllProdjects();
    getServices();
  }, []);
  useEffect(() => {
    if(projectsData){
      console.log(serviceFilter)
      if(serviceFilter !== "") {
        const filtredData = projectsData.length>= 1 && projectsData.filter(project => project.serviceId?.en?.name === serviceFilter);
        setProject(filtredData)
      }else {
        setProject(projectsData)
      }
    }
  },[projectsData , serviceFilter])

  useEffect(() => {
    if (Array.isArray(projectsData) && projectsData.length > 0) {
      const categoriesList = projectsData
        .map(project => project.serviceId?.en?.name)
        .filter(Boolean); // Filter out any undefined values
  
      const uniqCategories = [...new Set(categoriesList)];
      setAllServicesOfAllProjects(uniqCategories);
    }
  }, [projectsData]);
  useEffect(() => {
    console.log(allServicesOfAllProjects)
  },[allServicesOfAllProjects])
  const handleFilterProjects = (filterQuesry) => {
    setServiceFilter(filterQuesry);
    setShowFilterProjects(false)
  }
  return (
    <>
      {loading ? (
        <>
          <section className="tf-section section-work style-2 style-3 s1 padding-top-200">
            <div className="container">
              <div className="row d-flex justify-content-center gap-1">
                <CustomSpinner animation={"border"} />
              </div>
            </div>
          </section>
        </>
        
      ) : (
        <>
          {error ? (
            <>
              <Error />
            </>
          ) : (
            <>
              {project && (
                <>
                  {
                    <>
                      <section className="tf-section section-portfolio style-2 padding-top-200">
                        <div className="container">
                          <div className="row">
                            <div className="col-12 my-4  py-5">
                              <div className="row row-of-portfolio-header">
                                <div className=" col-sm-10 title-div title-of-portfolio">
                                  {language === "en" ? (
                                    <>
                                      <p className="projects-sub-title">
                                        discover all cases
                                      </p>
                                      <h3 className="projects-title">
                                        OUR PORTFOLIO
                                      </h3>
                                    </>
                                  ) : (
                                    <>
                                      <p className="projects-sub-title">
                                        استكشف كل المشاريع
                                      </p>
                                      <h3 className="projects-title">
                                        اعمالنا الفنية
                                      </h3>
                                    </>
                                  )}
                                </div>
                                {/* filtter div */}
                                {
                                  language === "en"
                                  ?
                                  <div className="col-lg-2 col-sm-2 filter-div align-items-center justify-content-end">
                                    <Button className="d-flex justify-content-center align-items-center text-white bg-primary" style={{width: "fit-content" , textDecoration: "none"}}
                                    onClick={() => setShowFilterProjects(true)}
                                    >
                                      <p className="text-sm filter-text" style={{fontSize: "15px"}}>Filter</p>
                                      <i className="fa-solid fa-filter"
                                      style={{fontSize: "20px" , cursor: "pointer" , opacity: '.8'}}
                                      ></i>
                                    </Button>
                                  </div>
                                  :
                                  <div className="col-lg-2 col-sm-2 filter-div align-items-center justify-content-end">
                                    <Button className="d-flex justify-content-center align-items-center text-white bg-primary" style={{width: "fit-content" , textDecoration: "none"}}
                                    onClick={() => setShowFilterProjects(true)}
                                    >
                                      <p className="text-sm filter-text" style={{fontSize: "15px"}}>فلتر</p>
                                      <i className="fa-solid fa-filter"
                                      style={{fontSize: "20px" , cursor: "pointer" , opacity: '.8'}}
                                      ></i>
                                    </Button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          {
                            project.length > 0 ? (
                            <div className="row card-projects-diplay">
                              {language === "en" ? (
                                <>
                                  {project.map((data, index) => (
                                    <>
                                      <Col
                                        key={index}
                                        className="py-2"
                                        lg={4}
                                        md={6}
                                        sm={12}
                                      >
                                        <div className=" themesflat-portfolio">
                                          <div className="project-item">
                                            <div className="inner">
                                              <div className="boxitem">
                                                <div className="imgbox">
                                                  <Link
                                                    className="cover"
                                                    to={`/portfolio/${data._id}`}
                                                  >
                                                    View
                                                  </Link>

                                                  <IMG
                                                    url={data.cover_image}
                                                    title={"data.en.name"}
                                                  />

                                                  {/* {data.cover_image ? (
                                                    <>
                                                      <img
                                                        src={`${process.env.REACT_APP_PUBLIC_API}/${data.cover_image}`}
                                                        alt={data.en.name}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <img
                                                        src={tempImage}
                                                        alt={"topgrowth img"}
                                                      />
                                                    </>
                                                  )} */}
                                                </div>
                                                <div className="content-box py-4">
                                                  {data.category &&
                                                    data.category.en.name && (
                                                      <h5 className="subtitle">
                                                        {data.category.en.name}
                                                      </h5>
                                                    )}
                                                  {data.en && data.en.name && (
                                                    <h3 className="title">
                                                      {data.en.name}
                                                    </h3>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {project.map((data, index) => (
                                    <Col
                                      key={index}
                                      className="py-2"
                                      lg={4}
                                      md={6}
                                      sm={12}
                                    >
                                      <div className=" themesflat-portfolio">
                                        <div className="project-item">
                                          <div className="inner">
                                            <div className="boxitem">
                                              <div className="imgbox">
                                                <Link
                                                  className="cover"
                                                  to={`/portfolio/${data._id}`}
                                                >
                                                  View
                                                </Link>
                                                <IMG
                                                    url={data.cover_image}
                                                    title={data.ar.name}
                                                  />
                                              </div>
                                              <div className="content-box py-4">
                                                {data.category &&
                                                  data.category.ar.name && (
                                                    <h5 className="subtitle">
                                                      {data.category.ar.name}
                                                    </h5>
                                                  )}
                                                {data.ar && data.ar.name && (
                                                  <h3 className="title">
                                                    {data.ar.name}
                                                  </h3>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </>
                              )}
                            </div>
                            )
                            : 
                            (
                              <>
                                <section className="tf-section padding-top-200">
                                  <div className="container">
                                    <div className="row  text-center">
                                      {language === "en" ? (
                                        <h3>No Projects add yet</h3>
                                      ) : (
                                        <h3>لا توجد مشاريع مضافة </h3>
                                      )}
                                    </div>
                                  </div>
                                </section>
                              </>
                            )
                          }
                        </div>
                      </section>
                    </>
                  }
                </>
              )}
            </>
          )}
        </>
      )}
      {/* <FilterProjectsNav position={language === "en" ? "end" : "start"} show={showFilterProjects} onHide={() => setShowFilterProjects(false)} /> */}
      
      <Offcanvas show={showFilterProjects} onHide={() => setShowFilterProjects(false)} placement={language === "en" ? "start" : "end"}>
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Filter Projects</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="tf-section section-portfolio style-2">
            <div className="col-sm-12 filter-div d-flex flex-column justify-content-center align-items-center">
              {
                services && services.length >= 1
                ?
                (
                  <>
                    <button className={`cutom-btn ${serviceFilter === "" ? "active" : ""}`}
                    onClick={() => handleFilterProjects("")}
                    >
                      {
                        language === "en"
                        ?
                        "All"
                        :
                        "الكل"
                      }
                      {/* <span className="btn-span-number">{allServicesOfAllProjects.length}</span> */}
                    </button>
                    {
                      services.map((serv , index) => {
                        const alreadyIn = allServicesOfAllProjects.find(s => s === serv.en.name);
                        if(alreadyIn) {
                          return (
                            <button className={`cutom-btn ${serv.en.name === serviceFilter ? "active" : ""}`}
                            key={index}
                            onClick={() => handleFilterProjects(serv.en.name)}
                            >
                              {
                                language === "en"
                                ?
                                serv.en.name
                                :
                                serv.ar.name
                              }
                              {/* <span className="btn-span-number"> 3</span> */}
                            </button>
                          )

                        }
                      })
                    }
                  </>
                )
                :
                (
                  language === "en"
                  ?
                  <p>No Services For Filtering!</p>
                  :
                  <p>لا يوجد خدمات للقيام بعمليه الفلتره!!</p>
                )
              }
            </div>

          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}



