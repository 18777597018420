import { configureStore } from "@reduxjs/toolkit";
import contentSlicer from "./Slicers/contactSlicer";
import intershipSlicer from "./Slicers/intershipSlicer";
//home
import getAllHomeDataSlicer from "./Slicers/Home/getAllHomeDataSlicer";

//services
import getAllServicesSlicer from "./Slicers/CServices/getAllServicesSlicer";
import getSingleServicesByIdSlicer from "./Slicers/CServices/getSingleServicesByIdSlicer";
import getAllServicesSlicerWithProjects from "./Slicers/CServices/getAllServicesSlicerWithProjects";
//Teams
import getAllTeamsSlicer from "./Slicers/Teams/getAllTeamsSlicer";

//subscribeSlicer
import subscribeSlicer from "./Slicers/Newslist/subscribeSlicer";

//Projects
import getAllProjectsSlicer from "./Slicers/Projects/getAllProjectsSlicer";
import getSingleProjectsSlicer from "./Slicers/Projects/getSingleProjectsSlicer";

//Carrers
import getAllCarrersSlicer from "./Slicers/Carrers/getAllCarrersSlicer";

const store = configureStore({
  reducer: {
    //home
    getAllHomeDataSlicer,
    //subscribe
    subscribeSlicer,
    //forms
    createContcat: contentSlicer,
    createIntership: intershipSlicer,

    //services
    getAllServicesSlicer: getAllServicesSlicer,
    getSingleServicesByIdSlicer: getSingleServicesByIdSlicer,
    getAllServicesSlicerWithProjects: getAllServicesSlicerWithProjects,

    //Teams
    getAllTeamsSlicer,

    //projects
    getAllProjectsSlicer: getAllProjectsSlicer,
    getSingleProjectsSlicer: getSingleProjectsSlicer,

    //carrers
    getAllCarrersSlicer: getAllCarrersSlicer,
  },
});

export default store;
