import AboutMain from "../components/AboutComponents/AboutMain";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import ScrollUp from "../components/ScrollUp";
import MainServices from "../components/ServicesCmponents/MainServices";
import ServicesMin from "../components/ServicesCmponents/ServicesMin";
import WhatsappBtn from "../components/WhatsappBtn";
import Wrapper from "../components/Wrapper";
import { Context } from "../App";
import { useContext, useEffect } from "react";
import TeamsSection from "../components/TeamsSection";
import AboutSection from "../components/AboutComponents/AboutSection";

export default function AboutPage() {
  const [language, setlanguage] = useContext(Context);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* {language === "en" ? (
        <PageTitle title="About Us" />
      ) : (
        <PageTitle title="من نحن وماذا تعرف عنا " />
      )} */}

      {/* <AboutMain /> */}
      <AboutSection />
      <TeamsSection />
      {/* <MainServices /> */}
    </>
  );
}
