export default function Icons(params) {
  const icon = [
    'icon-channel',
    'icon-stats',
    'icon-app',
    'icon-chart',
    'icon-development',
    'icon-graphic-design',
    'icon-phone-ringing',
    'icon-play',
    'icon-play-button',
    'icon-play',
  ];





const random = Math.floor(Math.random()*10);
console.log(random);
  return (
    <>
      <span className={icon[random]} />
    </>
  );
}
