import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Home from "./pages/Home";
import InternshipsPage from "./pages/InternshipsPage";
import WOW from "wowjs";

import { Outlet, Route, Routes } from "react-router-dom";
import ServicesPage from "./pages/ServicesPage";
import ErrorPage from "./pages/ErrorPage";
import SingleService from "./pages/SingleService";
import PortfilioPage from "./pages/PortfilioPage";
import SingleProjectDetails from "./pages/SingleProjectDetails";
import { createContext, useEffect, useState } from "react";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollUp from "./components/ScrollUp";
import WhatsappBtn from "./components/WhatsappBtn";
import NewSingleProject from "./components/PortfiliosComponetns/NewSingleProject";
import { useDispatch, useSelector } from "react-redux";
import { getHomeDataApi } from "./Store/Slicers/Home/getAllHomeDataSlicer";
import HomeLoader from "./components/HomeLoader";
import SingleProjectPage from "./components/PortfiliosComponetns/SingleProjectPage";
import CustomHeader from "./components/CustomHeader";
import MainNewsList from "./components/MainNewsList";
import ApplayCareerForm from "./pages/ApplayCareerForm";

export const Context = createContext();

export default function App() {
  const [language, setlanguage] = useState("en");
  const { loading, error } = useSelector((s) => s.getAllHomeDataSlicer);

  const dispatch = useDispatch();

  const [homeData, setHomeData] = useState();

  const getHomeData = async () => {
    dispatch(getHomeDataApi()).then((res) => {
      if (res.payload.message === "Data fetched successfully") {
        setHomeData(res.payload.data);
      } else {
        setHomeData("");
      }
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", language);
    } else {
      setlanguage(localStorage.getItem("language"));
      if (localStorage.getItem("language") === "en") {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.setAttribute("lang", "en");
      } else {
        document.documentElement.setAttribute("dir", "rtl");
        document.documentElement.setAttribute("lang", "ar");
      }
    }
  }, []);

  useEffect(() => {
    new WOW.WOW().init();
    getHomeData();
  }, []);
  useEffect(() => {
    console.log(homeData)
  },[homeData])
  if (!homeData) {
    return <HomeLoader />;
  } else {
    return (
      <Context.Provider value={[language, setlanguage]}>
        <Wrapper>
          <CustomHeader />
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<Home data={homeData} />} />
            <Route path="/about" Component={AboutPage} />
            {/* <Route path="/services" Component={ServicesPage} /> */}
            {/* <Route path="/services/:serviseId" Component={SingleService} /> */}
            <Route path="/portfolio" Component={PortfilioPage} />
            <Route path="/portfolio/:projectId" Component={SingleProjectPage} />
            {/* <Route path="/portfilio/:projectId" Component={NewSingleProject} /> */}
            {/* <Route path="/single-project" Component={SingleProjectDetails} />
            <Route path="/single" Component={NewSingleProject} /> */}
            <Route path="/contact" Component={ContactPage} />
            <Route path="/career" Component={InternshipsPage} />
            <Route path="/applay-career/:careerId" Component={ApplayCareerForm} />
            <Route path="*" Component={ErrorPage} />
          </Routes>
          <MainNewsList />
          <Footer />
          <ScrollUp />
          <WhatsappBtn />
        </Wrapper>
      </Context.Provider>
    );
  }
}
