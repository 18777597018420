import { Link } from "react-router-dom";
import { servicesCards } from "../../Services/services-details";
import Icons from "../Icons";
import "../../css/custom-services-style.css"

export default function CardService(props) {
  return (
    <>
      <div
        className="box-work wow slideInLeft animation_duration animation_timing col-lg-2 col-md-6 col-sm-12 service-box"
        style={{
          animationDuration: `${props.delay}`,
        }}
        data-wow-offset="10"
      >
        <div className="divider-left" />
        <div className="box-item">
          <Icons />
          <h6 className="title-box">
            {/* <Link to={`/services/${props.serviceData._id}`}> */}
            <Link to="#">
              {props.textData.name}
            </Link>
          </h6>
          <p className="text">{props.textData.description}</p>
          {/* <Link to={`/services/${props.serviceData._id}`} className="readmore">
            <i className="fas fa-chevron-right" />
          </Link> */}
        </div>
      </div>
    </>
  );
}
