import { Context } from "../App";
import { useContext } from "react";

export default function Couter() {
  const [language, setlanguage] = useContext(Context);

  const ArabicCouter = () => {
    return (
      <>
        <section className="tf-section section-counter style-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="heading-top wow fadeInDown">
                  <h4 className="sub-title padding-bottom-20">
                    تعرف علي قصتنا!
                  </h4>
                  <h3 className="title">
                    نقوم بعمل العجائب لعمبلائنا
                    <br />
                    شاهد بعض من لحظاتنا الجميلة
                  </h3>
                </div>
              </div>
            </div>
            {/*
    <div class="row">
       <div class="list-counter">
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v1">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="1250"
                      data-inviewport="yes"
                      >1250</span
                    >
                  </div>
                  <h3 class="heading margin-right-18">
                    Project<br />
                    completed
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v2">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap active">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="548"
                      data-inviewport="yes"
                      >548</span
                    >
                  </div>
                  <h3 class="heading margin-right-6">
                    Our<br />
                    Happy clients
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="themesflat-spacer clearfix"
          data-desktop="0"
          data-mobile="35"
          data-smobile="35"
        ></div>
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v3">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="356"
                      data-inviewport="yes"
                      >356</span
                    >
                  </div>
                  <h3 class="heading margin-right-8">
                    Cups<br />
                    Of coffee
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="themesflat-spacer clearfix"
          data-desktop="0"
          data-mobile="0"
          data-smobile="35"
        ></div>
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v4">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="295"
                      data-inviewport="yes"
                      >295</span
                    >
                  </div>
                  <h3 class="heading margin-right-10">
                    Win<br />
                    Awards
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div> 

    </div>
    */}
          </div>
        </section>
      </>
    );
  };
  const EnglishCouter = () => {
    return (
      <>
        <section className="tf-section section-counter style-2">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="heading-top wow fadeInDown">
                  <h4 className="sub-title padding-bottom-20">
                    Completed stories!
                  </h4>
                  <h3 className="title">
                    We do awesome contribute for our clients.
                    <br />
                    Check some of our counter.
                  </h3>
                </div>
              </div>
            </div>
            {/*
    <div class="row">
       <div class="list-counter">
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v1">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="1250"
                      data-inviewport="yes"
                      >1250</span
                    >
                  </div>
                  <h3 class="heading margin-right-18">
                    Project<br />
                    completed
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v2">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap active">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="548"
                      data-inviewport="yes"
                      >548</span
                    >
                  </div>
                  <h3 class="heading margin-right-6">
                    Our<br />
                    Happy clients
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="themesflat-spacer clearfix"
          data-desktop="0"
          data-mobile="35"
          data-smobile="35"
        ></div>
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v3">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="356"
                      data-inviewport="yes"
                      >356</span
                    >
                  </div>
                  <h3 class="heading margin-right-8">
                    Cups<br />
                    Of coffee
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="themesflat-spacer clearfix"
          data-desktop="0"
          data-mobile="0"
          data-smobile="35"
        ></div>
        <div
          class="themesflat-content-box"
          data-margin="0 0 0 0"
          data-mobilemargin="0 0 0 0"
        >
          <div class="themesflat-counter v4">
            <div class="counter-item">
              <div class="inner">
                <div class="text-wrap">
                  <div class="number-wrap">
                    <span
                      class="number"
                      data-speed="2000"
                      data-to="295"
                      data-inviewport="yes"
                      >295</span
                    >
                  </div>
                  <h3 class="heading margin-right-10">
                    Win<br />
                    Awards
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div> 

    </div>
    */}
          </div>
        </section>
      </>
    );
  };

  return <>{language === "en" ? <EnglishCouter /> : <ArabicCouter />}</>;
}
