import { Context } from "../../../App";
import { useContext, useRef, useState } from "react";

export default function HomeDataVideo({ data }) {
  console.log(data);
  const [language, setlanguage] = useContext(Context);
  const ref = useRef(null);
  const [video, setvideo] = useState(false);
  const handleClick = () => {
    setvideo(true);
    ref.current.src = ref.current.src + "&autoplay=1";
    console.log(ref.current.src);
  };

  return (
    <>
      {/* {language === "en" ? <EnglishVideo /> : <ArabicVideo />} */}
      <section className="tf-section section-video ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="box-video no-margin wow fadeInUp">
                <div className={`box ${video ? "video-active" : ""}`}>
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_API}/${data.image}`}
                  />
                  <div className="overlay" />

                  <div className="flat-video-fancybox">
                    <a
                      className="fancybox"
                      data-type="iframe"
                      //href={data.video_link}
                      target="_blank"
                      onClick={handleClick}
                    >
                      <span className="icon-play" />
                      <span className="ripple" />
                    </a>
                    <div className="box-content">
                      <h3 className="title-video">
                        {language === "en" ? data.en_title : data.ar_title}
                      </h3>
                    </div>
                  </div>
                </div>
                <div
                  className={`video-frame-box  ${video ? "video-active" : ""}`}
                >
                  <iframe
                    // src={`https://www.youtube.com/embed/0gOONmwTImY?autoplay=${video?"1":"0"}&&si=u9Wbu8BpryoAxL9-`}
                    src={data.video_link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    ref={ref}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
