import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Context } from "../App";
import { getTeamApi } from "../Store/Slicers/Teams/getAllTeamsSlicer";
import { Col } from "react-bootstrap";

export default function TeamsSection() {
  const [language, setlanguage] = useContext(Context);

  const { loading, error } = useSelector((s) => s.getAllTeamsSlicer);
  const dispatch = useDispatch();
  const [team, setTeam] = useState([]);

  const getAllTeam = () => {
    dispatch(getTeamApi()).then((res) => {
      if (res.payload.message == "Data fetched successfully") {
        setTeam(res.payload.data);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllTeam();
  }, []);

  return (
    <>
      {team && (
        <>
          {team.length > 0 ? (
            <>
              <section className="tf-section section-team ">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="heading-top wow fadeInDown">
                        {language === "en" ? (
                          <>
                            <h4 className="sub-title padding-bottom-20">
                              Our Team
                            </h4>
                            <h3 className="title style-2">
                              We do awesome contribute for our clients.
                              <br />
                              Check some of Team Member.
                            </h3>
                          </>
                        ) : (
                          <>
                            <h4 className="sub-title padding-bottom-20">
                              فريقنا
                            </h4>
                            <h3 className="title style-2">
                              لدينا فريق متكامل ورائع.
                              <br />
                              ومتخصص في ارضاء العملاء
                            </h3>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row custom-list-team justify-content-center">
                    {language === "en" ? (
                      <>
                        {team.map((item, index) => (
                          <Col
                            key={index}
                            lg={3}
                            md={6}
                            sm={12}
                            className="py-4 "
                          >
                            <div className="box-item-team wow fadeInUp">
                              <img
                                src={`${process.env.REACT_APP_PUBLIC_API}/${item.image}`}
                                alt={item.en.name}
                                className="imgbox"
                              />

                              {(item.social_links.fasebook !== "" ||
                                item.social_links.instagram !== "" ||
                                item.social_links.linkedin !== "" ||
                                item.social_links.behance !== "") && (
                                <div className="info">
                                  <ul className="list-social">
                                    {item.social_links.fasebook && (
                                      <li>
                                        <a
                                          href={item.social_links.fasebook}
                                          target="_blank"
                                        >
                                          <i className="fab fa-facebook-f" />
                                        </a>
                                      </li>
                                    )}

                                    {item.social_links.instagram && (
                                      <li>
                                        <a
                                          href={item.social_links.instagram}
                                          target="_blank"
                                        >
                                          <i className="fab fa-instagram" />
                                        </a>
                                      </li>
                                    )}

                                    {item.social_links.linkedin && (
                                      <li>
                                        <a
                                          href={item.social_links.linkedin}
                                          target="_blank"
                                        >
                                          <i className="fab fa-linkedin" />
                                        </a>
                                      </li>
                                    )}

                                    {item.social_links.behance && (
                                      <li>
                                        <a
                                          href={item.social_links.behance}
                                          target="_blank"
                                        >
                                          <i className="fab fa-behance" />
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )}
                              <div className="tag-info">
                                <h6 className="name">{item.en.name}</h6>
                                <p className="branch">{item.en.title}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </>
                    ) : (
                      <>
                        {team.map((item, index) => (
                          <Col
                            key={index}
                            lg={3}
                            md={6}
                            sm={12}
                            className="py-4 "
                          >
                            <div className="box-item-team wow fadeInUp">
                              <img
                                src={`${process.env.REACT_APP_PUBLIC_API}/${item.image}`}
                                alt={item.en.name}
                                className="imgbox"
                              />
                              {(item.social_links.fasebook !== "" ||
                                item.social_links.instagram !== "" ||
                                item.social_links.linkedin !== "" ||
                                item.social_links.behance !== "") && (
                                <div className="info">
                                  <ul className="list-social">
                                    {item.social_links.fasebook && (
                                      <li>
                                        <a
                                          href={item.social_links.fasebook}
                                          target="_blank"
                                        >
                                          <i className="fab fa-facebook-f" />
                                        </a>
                                      </li>
                                    )}

                                    {item.social_links.instagram && (
                                      <li>
                                        <a
                                          href={item.social_links.instagram}
                                          target="_blank"
                                        >
                                          <i className="fab fa-instagram" />
                                        </a>
                                      </li>
                                    )}

                                    {item.social_links.linkedin && (
                                      <li>
                                        <a
                                          href={item.social_links.linkedin}
                                          target="_blank"
                                        >
                                          <i className="fab fa-linkedin" />
                                        </a>
                                      </li>
                                    )}

                                    {item.social_links.behance && (
                                      <li>
                                        <a
                                          href={item.social_links.behance}
                                          target="_blank"
                                        >
                                          <i className="fab fa-behance" />
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              )}
                              <div className="tag-info">
                              <h6 className="name">{item.ar.name}</h6>
                              <p className="branch">{item.ar.title}</p>
                              </div>
                           
                            </div>
                          </Col>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
              <section className="tf-section padding-top-200">
                <div className="container">
                  <div className="row  text-center">
                    {language === "en" ? (
                      <h3>No team members add yet</h3>
                    ) : (
                      <h3>لا يوجد اعضاء في الفريق مضافين في الوقت الحالي </h3>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
}
