import { Link } from "react-router-dom";
import "../css/custom-footer-style.css"
import footerLogo from "../images/footer-logo-whit.png";
import emailIcon from "../images/icon/email.png";
import payment from "../images/payment/Partners-footer.webp";
import { Context } from "../App";
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import ar_logo from "../images/logos/ar_logo_cliped.png";
import en_logo from "../images/logos/en_logo_cliped.png";
import { useDispatch, useSelector } from "react-redux";
import { getServiceApi } from "../Store/Slicers/CServices/getAllServicesSlicer";

export default function Footer() {
  const [language, setlanguage] = useContext(Context);
  const { loading:getServicesLoading  , data:servicesData} = useSelector((s) => s.getAllServicesSlicer);
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const getServices = () => {
    dispatch(getServiceApi()).then((res) => {
      if (res.payload.message == "Data Fetched successfully") {
        console.log(res.payload)
        setServices(res.payload.data);
      }
    });
  };
  useEffect(() => {
    getServices();
  }, []);

  const ArabicFooter = () => {
    return (
      <>
        <footer className="footer" id="site-footer">
          <div>
            <section className="tf-section section-main-footer">
              <div className="overlay" />
              <div className="container z-index">
                <div className="row list-footer">
                  <Col lg={3} md={6} sm={12} className="widget-about">
                    <div>
                      <div className="logo">
                        <Link to={"/"}>
                          <img src={ar_logo} alt="logo" width={50} />
                        </Link>
                      </div>
                      <h6 className="text pt-2" style={{ textAlign: "justify" }}>
                        نحن نتصور الحملات الرقمية عالية التأثير ونضع
                        إستراتيجياتها ونشرف عليها والتي تدفع الشركات نحو أهدافها
                        وتعزز النمو. تلبي خدماتنا المصممة خصيصًا احتياجاتك
                        الخاصة، حيث تدير كل جانب من الجوانب بسلاسة من الألف إلى
                        الياء
                      </h6>
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    md={6}
                    sm={12}
                    className="widget-link v1 about-col-ar-footer"
                    style={{
                      paddingRight: "20px",
                    }}
                  >
                    <h6 className="widget-title">عنا</h6>
                    <ul className="list-wrap">
                      <li>
                        <Link to={"/about"}>من نحن</Link>
                      </li>
                      <li>
                        <Link to={"/portfolio"}>المشاريع</Link>
                      </li>
                      {/* <li>
                        <Link to={"/services"}>الخدمات</Link>
                      </li> */}
                      <li>
                        <Link to={"/contact"}>تواصل معنا</Link>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={3} md={6} sm={12} className=" widget-link v2"   style={{
                      paddingRight: "20px",
                    }}>
                    <h6 className="widget-title">مشاريعنا </h6>
                    <ul className="list-wrap">
                      {
                        services && services.length >= 1
                        ?
                        services.map((serv , index) => (
                          <li className="menu-item current-item">
                            <Link to={"/portfolio"}>{serv.ar.name}</Link>
                          </li>
                        ))
                        :
                        <li className="menu-item current-item">
                          <Link to={"/portfolio"}>لم يتم اضافه مشاريع</Link>
                        </li>
                      }
                      {/* <li className="menu-item current-item">
                        <Link to={"/portfolio"}>تصميم المواقع</Link>
                      </li>
                      <li className="menu-item current-item">
                        <Link to={"/portfolio"}>التسويق الالكتروني</Link>
                      </li>
                      <li className="menu-item current-item">
                        <Link to={"/portfolio"}>العلامات التجارية</Link>
                      </li>
                      <li className="menu-item current-item">
                        <Link to={"/portfolio"}>تعديل الفيديوهات </Link>
                      </li> */}
                    </ul>
                  </Col>
                  <Col lg={3} md={6} sm={12} className="widget-link v3"   style={{
                      paddingRight: "20px",
                    }}>
                    <h6 className="widget-title">الدعم</h6>
                    <ul className="list-wrap">
                      <li>
                        <Link to={"/contact"}>تواصل معنا</Link>
                      </li>
                      <li>
                        <Link to={"/career"}>انضم الينا</Link>
                      </li>
                      <div className="widget-about">
                        <div className="location">
                          <div className="icon-location">
                            <img src={emailIcon} alt width={40} />
                          </div>
                          <p className="title">البريد الالكتروني</p>
                          <p className="info">info@topgrowth-eg.com</p>
                        </div>
                      </div>
                    </ul>
                  </Col>
                </div>
                {/* <div className="row mt-3">
                  <img src={payment} alt="payment method" />
                </div> */}
              </div>
            </section>
            {/* Bottom Footer */}
            <section className="bottom-footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="copyright">
                      <p>
                        تم التصميم بواسطة <span>@TopGrowth</span>
                        وكل الحقوق محفوظة
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="flat-text-left">
                      {/* <ul className="list">
                        <li className="li">
                          <Link href="#">السياسات</Link>
                        </li>
                        <li className="li">
                          <Link href="#">Terms &amp; الشروط</Link>
                        </li>
                        <li className="li">
                          <Link href="#">المدونة</Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </footer>
      </>
    );
  };
  const EnglishFooter = () => {
    return (
      <>
        <footer className="footer" id="site-footer">
          <div>
            <section className="tf-section section-main-footer">
              <div className="overlay" />
              <div className="container z-index">
                <div className="row list-footer">
                  <Col lg={3} md={6} sm={12} className="widget-about">
                    <div>
                      <div className="logo">
                        <Link to={"/"}>
                          <img src={en_logo} alt="logo" width={50} />
                        </Link>
                      </div>
                      <h6 className="text english-footer pt-2">
                        We conceptualize, strategize, and oversee high-impact
                        digital campaigns that propel businesses toward their
                        objectives and foster growth. 
                        <br/>Our tailored services
                        cater to your specific needs, managing every aspect
                        seamlessly from A to Z
                        {/* and foster growth. Our tailored services and foster growth. Our tailored services and foster growth. Our tailored services and foster growth. Our tailored services */}
                      </h6>
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    md={6}
                    sm={12}
                    className="widget-link v1 about-col-eng-footer"
                    style={{
                      paddingLeft: "20px",
                    }}
                  >
                    <h6 className="widget-title">About Us</h6>
                    <ul className="list-wrap">
                      <li>
                        <Link to={"/about"}>About Us</Link>
                      </li>
                      <li>
                        <Link to={"/portfolio"}>Our Portfolio</Link>
                      </li>
                      {/* <li>
                        <Link to={"/services"}>Our Services</Link>
                      </li> */}
                      <li>
                        <Link to={"/contact"}>Get Contact</Link>
                      </li>
                    </ul>
                  </Col>
                  <Col lg={3} md={6} sm={12} className=" widget-link v2" style={{
                      paddingLeft: "20px",
                    }}>
                    <h6 className="widget-title">Portfolio </h6>
                    <ul className="list-wrap">
                      {
                        services && services.length >= 1
                        ?
                        services.map((serv , index) => (
                          <li className="menu-item current-item">
                            <Link to={"/portfolio"}>{serv.en.name}</Link>
                          </li>
                        ))
                        :
                        <li className="menu-item current-item">
                          <Link to={"/portfolio"}>There is no any projects!</Link>
                        </li>
                      }
                      {/* <li className="menu-item current-item">
                        <Link to={"/portfolio"}>Web Development</Link>
                      </li>
                      <li className="menu-item current-item">
                        <Link to={"/portfolio"}>Social Media Markting</Link>
                      </li>
                      <li className="menu-item current-item">
                        <Link to={"/portfolio"}>Branding</Link>
                      </li>
                      <li className="menu-item current-item">
                        <Link to={"/portfolio"}>Video Editing </Link>
                      </li> */}
                    </ul>
                  </Col>
                  <Col lg={3} md={6} sm={12} className="widget-link v3" style={{
                      paddingLeft: "20px",
                    }}>
                    <h6 className="widget-title">Support</h6>
                    <ul className="list-wrap">
                      <li>
                        <Link to={"/contact"}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to={"/career"}>Join Us</Link>
                      </li>
                      <div className="widget-about">
                        <div className="location">
                          <div className="icon-location">
                            <img src={emailIcon} alt width={40} />
                          </div>
                          <p className="title">Email Adrress</p>
                          <p className="info">info@topgrowth-eg.com</p>
                        </div>
                      </div>
                    </ul>
                  </Col>
                </div>
                {/* <div className="row mt-3">
                  <img src={payment} alt="payment method" />
                </div> */}
              </div>
            </section>
            {/* Bottom Footer */}
            <section className="bottom-footer">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="copyright">
                      <p>
                        Copywright by <span>@TopGrowth</span> {""}
                        Rights Reserved
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="flat-text-right">
                      {/* <ul className="list">
                        <li className="li">
                          <Link href="#">Privacy</Link>
                        </li>
                        <li className="li">
                          <Link href="#">Terms &amp; Conditions</Link>
                        </li>
                        <li className="li">
                          <Link href="#">Blog</Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </footer>
      </>
    );
  };

  return <>{language === "en" ? <EnglishFooter /> : <ArabicFooter />}</>;
}
