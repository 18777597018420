import { Col, Row, Card, Carousel } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Context } from "../../App";
import { useContext } from "react";
import { getProjectsByIdApi } from "../../Store/Slicers/Projects/getSingleProjectsSlicer";
import CustomSpinner from "../CustomSpinner";
import Error from "../Error";
import IMG from "../IMG";

export default function SingleProjectPage() {
  const [language, setlanguage] = useContext(Context);

  const { projectId } = useParams();
  const { loading, error } = useSelector((s) => s.getSingleProjectsSlicer);
  const dispatch = useDispatch();
  const [project, setProject] = useState();

  const getProject = () => {
    dispatch(getProjectsByIdApi(projectId)).then((res) => {
      if (res.payload.message == "Data fetched successfully") {
        setProject(res.payload.data);
        console.log(res.payload.data);
      }
    });
  };

  useEffect(() => {
    getProject();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* {language === "en" ? <> <PageTitle title={"Project Details"} /></> : <> <PageTitle title={"تفاصيل عن المشروع"} /></>} */}

      {loading ? (
        <>
          <section className="tf-section section-project-details padding-top-200">
            <div className="container">
              <div className="row d-flex justify-content-center gap-1">
                <CustomSpinner animation={"border"} />
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {error ? (
            <>
              <Error />
            </>
          ) : (
            <>
              {project && (
                <>
                  <section className="tf-section section-project-details padding-top-200">
                    <div className="container">
                      {language === "en" ? (
                        <>
                          <Row className="main-project-details px-4">
                            <Col lg={6} className="custon-project-content">
                              {/* <span className="custom-badge badge">
                                {project.category.en.name}
                              </span> */}
                              <h2 className="title">{project.en.name}</h2>
                              <p className="sub-title">{project.en.title}</p>
                              <p className="description padding-top-30">
                                {project.en.description}
                              </p>
                            </Col>
                            {/* <Col lg={3} className="cutom-slidebar">
                              <ul className="box-author">
                                <li>
                                  Client: <b> {project.en.client_name}</b>
                                </li>
                                <li>
                                  Release Date : <b> {project.release_date} </b>
                                </li>
                              </ul>
                            </Col> */}
                            <Col lg={6} className="project-side-details">
                              <div className="row side-details-info-divs">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="inner-contetext">
                                    <p>Strategy</p>
                                    <p>
                                      <b> {project.category.en.name}</b>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="inner-contetext">
                                    <p>Date</p>
                                    <p>
                                      <b> {project.release_date}</b>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="inner-contetext">
                                    <p>Client</p>
                                    <p>
                                      <b> {project.en.client_name}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row className="main-project-details px-4">
                            <Col lg={6} className="custon-project-content">
                              {/* <span className="custom-badge badge">
                                {project.category.ar.name}
                              </span> */}
                              <h2 className="title">{project.ar.name}</h2>
                              <p className="sub-title">{project.ar.title}</p>
                              <p className="description padding-top-30">
                                {project.ar.description}
                              </p>
                            </Col>
                            {/* <Col lg={3} className="cutom-slidebar">
                              <ul className="box-author">
                                <li>
                                  العميل: <b> {project.ar.client_name}</b>
                                </li>
                                <li>
                                  تاريخ النشر : <b> {project.release_date} </b>
                                </li>
                              </ul>
                            </Col> */}
                            <Col lg={6} className="project-side-details">
                              <div className="row side-details-info-divs">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="inner-contetext">
                                    <p>الفئة</p>
                                    <p>
                                      <b> {project.category.ar.name}</b>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="inner-contetext">
                                    <p>تاريخ النشر</p>
                                    <p>
                                      <b> {project.release_date}</b>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                  <div className="inner-contetext">
                                    <p>العميل</p>
                                    <p>
                                      <b> {project.ar.client_name}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="padding-top-50">
                        {project.diplay_layout == 1 ? (
                          <>
                            {project.images.map((projectImg, index) => (
                              <Col
                                key={index}
                                lg={12}
                                md={6}
                                sm={12}
                                className="py-3"
                              >
                                {/* <img
                                  src={`${process.env.REACT_APP_PUBLIC_API}/${projectImg}`}
                                /> */}
                                <IMG
                                  url={projectImg}
                                  title={`project image ${index}`}
                                />
                              </Col>
                            ))}
                          </>
                        ) : (
                          <>
                            {project.diplay_layout == 2 ? (
                              <>
                                {project.images.map((projectImg, index) => (
                                  <Col
                                    key={index}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    className="py-3"
                                  >
                                    {/* <img
                                      src={`${process.env.REACT_APP_PUBLIC_API}/${projectImg}`}
                                    /> */}
                                    <IMG
                                      url={projectImg}
                                      title={`project image ${index}`}
                                    />
                                  </Col>
                                ))}
                              </>
                            ) : (
                              <>
                                {project.images.map((projectImg, index) => (
                                  <Col
                                    key={index}
                                    lg={4}
                                    md={6}
                                    sm={12}
                                    className="py-3"
                                  >
                                    {/* <img
                                      src={`${process.env.REACT_APP_PUBLIC_API}/${projectImg}`}
                                    /> */}
                                    <IMG
                                      url={projectImg}
                                      title={`project image ${index}`}
                                    />
                                  </Col>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Row>
                    </div>
                  </section>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
