import { Link } from "react-router-dom";
import { Context } from "../App";
import { useContext } from "react";
export default function Error() {
  const [language, setlanguage] = useContext(Context);

  return (
    <>
      <section className="error-section tf-section section-work style-2 style-3 s1 padding-top-200">
        <div className="container">
          <div className="row">
            <div className="col error_div text-center">
              {language === "en" ? (
                <>
                  <h1>404</h1>
                  <h3>Opps</h3>
                  <h5>
                    We're Sorry, it looks like something wrong with server or
                    the page that you lokking for isn't in our system
                    <br />
                    <div
                      className="btn-main wow fadeInLeft"
                      data-wow-delay="400ms"
                    >
                      <Link to={"/"}>
                        <span className="more">
                          BACK TO HOME
                          <span className="icon">
                            <i className="fas fa-plus" />
                          </span>
                        </span>
                      </Link>
                    </div>
                  </h5>
                </>
              ) : (
                <>
                  <h1>404</h1>
                  <h3>اووبس</h3>
                  <h5>
                  عذرًا، يبدو أن هناك خطأ ما في الخادم أو أن الصفحة التي تبحث عنها غير موجودة في نظامنا
                    <br />
                    <div
                      className="btn-main wow fadeInLeft"
                      data-wow-delay="400ms"
                    >
                      <Link to={"/"}>
                        <span className="more">
                         العودة الي الصفحة الرئيسية
                          <span className="icon">
                            <i className="fas fa-plus" />
                          </span>
                        </span>
                      </Link>
                    </div>
                  </h5>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
