import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const postIntership = createAsyncThunk(
  "intership/sendMessage",
  async (intershiperData) => {
    console.log(intershiperData);
    var requestOptions = {
      method: "POST",
      body: intershiperData,
    };

    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/user/intership/create`,
      requestOptions
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((err) => err);

    return request;
  }
);

const intershipSlicer = createSlice({
  name: "intership",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postIntership.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postIntership.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message == "Message sent successfully") {
        state.data = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(postIntership.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default intershipSlicer.reducer;
