import { useParams } from "react-router-dom";
import InternshipForm from "../components/Forms/InternshipForm";
import { useEffect } from "react";

export default function ApplayCareerForm() {
    const {careerId} = useParams();
    useEffect(() => {
        console.log(careerId)
    },[careerId])
    return (
        <>
            <InternshipForm />
        </>
    )
}