import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Alert } from "react-bootstrap";
import { getContact } from "../../Store/Slicers/contactSlicer";
import { Context } from "../../App";
import { useContext } from "react";

export default function ContactForm() {
  const [language, setlanguage] = useContext(Context);

  const { loading, data, error } = useSelector((state) => state.createContcat);
  const dispatch = useDispatch();



  const ArabicContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
  
    const showAndHideMessage = () => {
      setTimeout(() => {
      
        setResponseMessage("");
        setErrorMessage("");
      }, 5000);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const messageData = {
        name,
        email,
        message,
      };
      dispatch(getContact(messageData)).then((res) => {
        if (res.payload.message === "Message sent successfully") {
          setResponseMessage(res.payload.message);
          setName("");
          setEmail("");
          setMessage("");
        } else {
          setErrorMessage(res.payload.message);
        }
        showAndHideMessage();
      });
    };
    return (
      <>
        <section className="tf-section section-contact padding-top-200">
          <div className="container">
            <div className="row">
              <h2 className="padding-bottom-20">تواصل معنا </h2>
              <div className="col-lg-4 col-md-5 wow fadeInLeft">
                <div className="info-contact" dir="rtl">
                  <div className="address">
                    <span>القاهرة - مصر </span>
                    <h5 className="title">العنوان</h5>
                  </div>
                  <div className="telephone padding-top-70">
                    <span>01069273070</span>
                    <h5 className="title">الهاتف</h5>
                  </div>
                  <div className="email padding-top-70">
                    <span>info@topgrowth-eg.com</span>
                    <h5 className="title">البريد الالكتروني</h5>
                  </div>
                  <div className="social padding-top-70">
                    <h5 className="title">تابعنا </h5>
                    <ul className="list-social">
                      <li>
                        <a href="https://www.facebook.com/topgrowthagency">
                          <i className="fab fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/topgrowth.eg?igsh=b3g4aDBrcGV5ampp">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/top-growth-agency-b7a218241">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.behance.net/topgrowth-eg">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-8 col-md-7 padding-top-70 wow fadeInRight"
                data-wow-delay="400ms"
              >
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={0}
                  data-mobile
                  data-smobile={70}
                />
                <div className="form-contact">
                  <p>
                  ادخل البيانات المطلوبة <span> * </span>
                  </p>
                  <div className="themesflat-contact-form style-2 clearfix padding-top-50">
                    <form
                      id="contactform"
                      className="form-submit contact-form wpcf7-form"
                      onSubmit={handleSubmit}
                    >
                      <span className="wpcf7-form-control-wrap your-name">
                        <input
                          type="text"
                          tabIndex={1}
                          className="wpcf7-form-control"
                          placeholder="ادخل الاسم بالكامل"
                          required
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          tabIndex={3}
                          id="email"
                          className="wpcf7-form-control"
                          placeholder="البريد الالكتروني"
                          required
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          name="message"
                          tabIndex={5}
                          cols={40}
                          rows={10}
                          className="wpcf7-form-control wpcf7-textarea"
                          placeholder="اكتب رسالتك الان "
                          required
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          value={message}
                        />
                      </span>
                      <div className="btn-main">
                        {loading ? (
                          <>
                            <button
                              disabled
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                <Spinner animation="border" />;
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                <span className="icon">
                                  <i className="fas fa-plus" />
                                </span>
                                ارسال الان
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                      <div className="col mt-3">
                        {responseMessage && (
                          <>
                            <Alert variant={"success"}>{responseMessage}</Alert>
                          </>
                        )}
                        {errorMessage && (
                          <>
                            <Alert variant={"danger"}>{errorMessage}</Alert>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                  {/* /.themesflat-contact-form */}
                </div>
              </div>
          
              
            </div>
          </div>
        </section>
      </>
    );
  };
  const EnglishContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
  
    const showAndHideMessage = () => {
      setTimeout(() => {
        setResponseMessage("");
        setErrorMessage("");
      }, 5000);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const messageData = {
        name,
        email,
        message,
      };
      dispatch(getContact(messageData)).then((res) => {
        if (res.payload.message === "Message sent successfully") {
          setResponseMessage(res.payload.message);
          setName("");
          setEmail("");
          setMessage("");
        } else {
          setErrorMessage(res.payload.message);
        }
        showAndHideMessage();
      });
    };
    return (
      <>
        <section className="tf-section section-contact padding-top-200">
          <div className="container">
            <div className="row">
              <h2 className="padding-bottom-20">Get In Touch</h2>
              <div className="col-lg-4 col-md-5 wow fadeInLeft">
                <div className="info-contact">
                  <div className="address">
                    <h5 className="title">Address</h5>
                    <span>Cairo - Egypt</span>
                  </div>
                  <div className="telephone padding-top-70">
                    <h5 className="title">Telephone</h5>
                    <span>01069273070</span>
                  </div>
                  <div className="email padding-top-70">
                    <h5 className="title">E-mail</h5>
                    <span>info@topgrowth-eg.com</span>
                  </div>
                  <div className="social padding-top-70">
                    <h5 className="title">Social</h5>
                    <ul className="list-social">
                      <li>
                        <a href="https://www.facebook.com/topgrowthagency">
                          <i className="fab fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/topgrowth.eg?igsh=b3g4aDBrcGV5ampp">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/top-growth-agency-b7a218241">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.behance.net/topgrowth-eg">
                          <i className="fab fa-behance" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-8 col-md-7 padding-top-70 wow fadeInRight"
                data-wow-delay="400ms"
              >
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={0}
                  data-mobile
                  data-smobile={70}
                />
                <div className="form-contact">
                  <p>
                    Please Enter Your Comments <span>*</span>
                  </p>
                  <div className="themesflat-contact-form style-2 clearfix padding-top-50">
                    <form
                      id="contactform"
                      className="form-submit contact-form wpcf7-form"
                      onSubmit={handleSubmit}
                    >
                      <span className="wpcf7-form-control-wrap your-name">
                        <input
                          type="text"
                          tabIndex={1}
                          className="wpcf7-form-control"
                          placeholder="Nick name :"
                          required
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          tabIndex={3}
                          id="email"
                          className="wpcf7-form-control"
                          placeholder="Email Address :"
                          required
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          value={email}
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          name="message"
                          tabIndex={5}
                          cols={40}
                          rows={10}
                          className="wpcf7-form-control wpcf7-textarea"
                          placeholder="Write a message :"
                          required
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          value={message}
                        />
                      </span>
                      <div className="btn-main">
                        {loading ? (
                          <>
                            <button
                              disabled
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                <Spinner animation="border" />;
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                Submit Now
                              
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                      <div className="col mt-3">
                        {responseMessage && (
                          <>
                            <Alert variant={"success"}>{responseMessage}</Alert>
                          </>
                        )}
                        {errorMessage && (
                          <>
                            <Alert variant={"danger"}>{errorMessage}</Alert>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                  {/* /.themesflat-contact-form */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>{language === "en" ? <EnglishContactForm /> : <ArabicContactForm />}</>
  );
}
