import { useEffect, useState } from "react";
import CustomAlert, { SpanAlert } from "../CustomAlert";
import { Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { postIntership } from "../../Store/Slicers/intershipSlicer";
import { Context } from "../../App";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function InternshipForm() {
  const [language, setlanguage] = useContext(Context);
  const {careerId} = useParams();
  useEffect(() => {
    console.log(careerId)
  },[careerId])
  const ArabicInternshipForm = () => {
    const navegator = useNavigate();
    const { loading, error } = useSelector((state) => state.createIntership);
    const dispatch = useDispatch();
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [yearsOfEXP, setYearsOfEXP] = useState("");
    const [prevExperince, setPrevExperince] = useState("");
    const [phone, setPhone] = useState("");
    const [university, setUniversity] = useState("");
    const [mjOfStudy, setMjOfStudy] = useState("");
    const [ysOfStudy, setYsOfStudy] = useState("");
    // const [message, setMessage] = useState("");
    const [fileUploader, setFileUploader] = useState("");

    const [formErrorMessages, setFormErrorMessages] = useState({
      name: "",
      email: "",
      phone: "",
      university: "",
      mjOfStudy: "",
      ysOfStudy: "",
      // message: "",
      fileMessage:"",
      yearsOfStudy: "",
      prevExperince: ""
    });

    const showAndHideMessage = () => {
      setTimeout(() => {
        setResponseMessage("");
        setErrorMessage("");
        navegator("/career");
      }, 3000);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log("submitted");

      if (
        formErrorMessages.name === "" &&
        formErrorMessages.yearsOfStudy === "" &&
        formErrorMessages.ysOfStudy === "" &&
        formErrorMessages.email === "" &&
        formErrorMessages.phone === "" &&
        formErrorMessages.university === "" &&
        formErrorMessages.prevExperince === "" &&
        formErrorMessages.mjOfStudy === "" &&
        // formErrorMessages.message === "" &&
        formErrorMessages.fileMessage === ""
      ) {
        const formaData = new FormData();
        formaData.append("name", name);
        formaData.append("gender", gender);
        formaData.append("email", email);
        formaData.append("ysOfEx", yearsOfEXP);
        formaData.append("prev_experince", prevExperince);
        formaData.append("phone", phone);
        formaData.append("university", university);
        formaData.append("mjOfStudy", mjOfStudy);
        formaData.append("ysOfStudy", ysOfStudy);
        formaData.append("carrer_id", careerId);
        // formaData.append("message", message);
        if (fileUploader) {
          formaData.append("file", fileUploader);
        }
        dispatch(postIntership(formaData)).then((res) => {
          if (res.payload.message === "Message sent successfully") {
            setResponseMessage(res.payload.message);
            setName("");
            setGender("");
            setEmail("");
            setPhone("");
            setUniversity("");
            setMjOfStudy("");
            setYsOfStudy("");
            setPrevExperince("")
            // setMessage("");
            setFileUploader(null);
            showAndHideMessage();
          } else {
            setErrorMessage(res.payload.message);
          }
        });
      } else {
        window.alert("Please Fill All Fields");
      }
    };
    return (
      <>
        <section className="tf-section section-contact" style={{paddingTop:"140px"}} id="intership-form">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-8 m-auto col-md-7 wow fadeInRight"
                data-wow-delay="400ms"
              >
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={0}
                  data-mobile
                  data-smobile={70}
                />
                <div className="form-contact">
                  <h2>يمكنك الانضمام الينا </h2>
                  <p>
                    ادخل البيانات المطلوبة <span>*</span>
                  </p>
                  <div className="themesflat-contact-form style-2 clearfix padding-top-50">
                    <form
                      id="contactform"
                      className="form-submit contact-form wpcf7-form"
                      onSubmit={handleSubmit}
                    >
{/*name */}
                      <span className="wpcf7-form-control-wrap your-name">
                        <input
                          type="text"
                          tabIndex={1}
                          id="name"
                          name="name"
                          className="wpcf7-form-control"
                          placeholder="الاسم بالكامل"
                          required
                          value={name}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              name: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                name: "يجب ان  لا يقل الاسم عن 3 احرف",
                              });
                            }
                            if (e.target.value.length > 50) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                name: "يجب ان لا يزيد الاسم عن 50 حرف",
                              });
                            }
                            setName(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.name && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.name}
                        />
                      )}
{/*gender */}
                      <span className="wpcf7-form-control-wrap gender">
                        <p>النوع</p>
                        <div className="p-3 d-flex align-items-center">
                          <input
                            tabIndex={2}
                            type="radio"
                            id="r-b-male"
                            name="gender"
                            className="wpcf7-form-control"
                            required
                            checked={gender === "male" ? "checked" : ""}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            value={"male"}
                          />
                          <label htmlFor="r-b-male" className="px-4">
                            ذكر
                          </label>
                        </div>
                        <div className="p-3 d-flex align-items-center">
                          <input
                            type="radio"
                            id="r-b-female"
                            name="gender"
                            className="wpcf7-form-control"
                            required
                            checked={gender === "female" ? "checked" : ""}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            value={"female"}
                          />
                          <label htmlFor="r-b-female" className="px-4">
                            انثي
                          </label>
                        </div>
                      </span>
{/*email */}
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          tabIndex={3}
                          id="email"
                          name="email"
                          className="wpcf7-form-control"
                          placeholder="البريد الالكتروني"
                          required
                          value={email}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              email: "",
                            });
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (!emailRegex.test(e.target.value)) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                email: "البريد الالكتروني غير صالح",
                              });
                            }
                            if (e.target.value.length < 10) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                email: "البريد الالكتروني قصير جدا",
                              });
                            }
                            setEmail(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.email && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.email}
                        />
                      )}
{/*phone */}
                      <span className="wpcf7-form-control-wrap your-phone">
                        <input
                          type="text"
                          tabIndex={4}
                          id="phone"
                          name="phone"
                          className="wpcf7-form-control"
                          placeholder="رقم الهاتف"
                          required
                          value={phone}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              phone: "",
                            });

                            const phoneRegEx = /^\d{11}$/;

                            if (!phoneRegEx.test(e.target.value)) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                phone: "رقم غير صالح",
                              });
                            }
                            if (e.target.value.length < 11) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                phone: "رقم الهاتف يجب ان يكون 11 رقم ",
                              });
                            }
                            if (e.target.value.length > 11) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                phone: "رقم الهاتف اكبر من 11 رقم",
                              });
                            }
                            setPhone(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.phone && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.phone}
                        />
                      )}
{/*university */}
                      <span className="wpcf7-form-control-wrap your-university ">
                        <input
                          type="text"
                          tabIndex={5}
                          id="university "
                          name="university "
                          className="wpcf7-form-control"
                          placeholder="اسم الجامعة"
                          required
                          value={university}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              university: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                university: "يجب ان  لا يقل الاسم عن 3 احرف",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                university:
                                  " يجب ان  لا يزيد الاسم عن 200  حرف",
                              });
                            }
                            setUniversity(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.university && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.university}
                        />
                      )}
{/*major*/}
                      <span className="wpcf7-form-control-wrap your-major">
                        <input
                          type="text"
                          tabIndex={6}
                          id="major "
                          name="major "
                          className="wpcf7-form-control"
                          placeholder="مجال الدراسة"
                          required
                          value={mjOfStudy}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              mjOfStudy: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                mjOfStudy: " يجب ان  لا يقل الاسم عن 3  احرف",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                mjOfStudy: " يجب ان  لا يزيد الاسم عن 200  حرف",
                              });
                            }
                            setMjOfStudy(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.mjOfStudy && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.mjOfStudy}
                        />
                      )}
{/*years of study*/}
                      <span className="wpcf7-form-control-wrap your-study-year">
                        <input
                          type="text"
                          tabIndex={7}
                          id="study-year "
                          name="study-year "
                          className="wpcf7-form-control"
                          placeholder="سنوات الدراسة"
                          required
                          value={ysOfStudy}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              ysOfStudy: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                ysOfStudy: "يجب ان  لا يقل  عن 3 احرف",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                ysOfStudy: "يجب ان  لا يزيد  عن 200 حرف",
                              });
                            }
                            setYsOfStudy(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.ysOfStudy && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.ysOfStudy}
                        />
                      )}
{/*years of exp*/}
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="text"
                          tabIndex={3}
                          id="email"
                          name="ysOfEx"
                          className="wpcf7-form-control"
                          placeholder="عدد سنوات الخبرة"
                          required
                          value={yearsOfEXP}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              yearsOfStudy: "",
                            });
                            if (e.target.value.length < 1) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                yearsOfStudy: "لا يمكن ترك الحقل فارغ",
                              });
                            }
                            setYearsOfEXP(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.yearsOfStudy && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.yearsOfStudy}
                        />
                      )}
{/*prev exp*/}
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          name="prevExperince"
                          tabIndex={8}
                          cols={40}
                          rows={10}
                          className="wpcf7-form-control wpcf7-textarea"
                          required
                          placeholder="الخبرة السابقة"
                          value={prevExperince}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              prevExperince: "",
                            });
                            if (e.target.value.length < 1) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                prevExperince: "لا يمكن ترك الحقل فارغ",
                              });
                            }
                            setPrevExperince(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.prevExperince && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.prevExperince}
                        />
                      )}
{/*CV*/}
                      <span className="wpcf7-form-control-wrap cv-upload">
                        <p>
                          {" "}
                          ارفق السيرة الذاتية (word,pdf){" "}
                          <span className="text-danger">
                            (اقصي حجم 5 ميجابايت )
                          </span>
                        </p>
                        <input
                          type="file"
                          tabIndex={9}
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          className="wpcf7-form-control form-control-md"
                          required
                          onChange={(e) => {
                            setFileUploader(e.target.files[0]);
                            setFormErrorMessages({
                              ...formErrorMessages,
                              fileMessage: "",
                            });

                            if (e.target.files[0].size > 6000000) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                fileMessage: "حجم الملف كبير جدا",
                              });
                            }
                            
                          }}
                        />
                      </span>
                      {formErrorMessages.fileMessage && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.fileMessage}
                        />
                      )}

                      <div className="btn-main">
                        {loading ? (
                          <>
                            <button
                              disabled
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                <Spinner animation="border" />
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">ارسال الان</span>
                            </button>
                          </>
                        )}
                      </div>
                      <div className="col mt-3">
                        {responseMessage && (
                          <>
                            <Alert variant={"success"}>{responseMessage}</Alert>
                          </>
                        )}
                        {errorMessage && (
                          <>
                            <Alert variant={"danger"}>{errorMessage}</Alert>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  const EnglishInternshipForm = () => {
    const navegator = useNavigate();

    const { loading, error } = useSelector((state) => state.createIntership);
    const dispatch = useDispatch();
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [yearsOfEXP, setYearsOfEXP] = useState("");
    const [prevExperince, setPrevExperince] = useState("");
    const [phone, setPhone] = useState("");
    const [university, setUniversity] = useState("");
    const [mjOfStudy, setMjOfStudy] = useState("");
    const [ysOfStudy, setYsOfStudy] = useState("");
    // const [message, setMessage] = useState("");
    const [fileUploader, setFileUploader] = useState();

    const [formErrorMessages, setFormErrorMessages] = useState({
      name: "",
      email: "",
      phone: "",
      university: "",
      mjOfStudy: "",
      ysOfStudy: "",
      // message: "",
      fileMessage: "",
      yearsOfEXP: "",
      prevExperince: ""
    });

    const showAndHideMessage = () => {
      setTimeout(() => {
        setResponseMessage("");
        setErrorMessage("");
        navegator("/career");
      }, 3000);
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log("submitted");

      if (
        formErrorMessages.name === "" &&
        formErrorMessages.email === "" &&
        formErrorMessages.phone === "" &&
        formErrorMessages.yearsOfEXP === "" &&
        formErrorMessages.ysOfStudy === "" &&
        formErrorMessages.prevExperince === "" &&
        formErrorMessages.university === "" &&
        formErrorMessages.mjOfStudy === "" &&
        // formErrorMessages.message === "" &&
        formErrorMessages.fileMessage === ""
      ) {
        const formaData = new FormData();
        formaData.append("name", name);
        formaData.append("gender", gender);
        formaData.append("email", email);
        formaData.append("ysOfEx", yearsOfEXP);
        formaData.append("prev_experince", prevExperince);
        formaData.append("phone", phone);
        formaData.append("university", university);
        formaData.append("mjOfStudy", mjOfStudy);
        formaData.append("ysOfStudy", ysOfStudy);
        formaData.append("carrer_id", careerId);
        // formaData.append("message", message);
        if (fileUploader) {
          formaData.append("file", fileUploader);
        }
        dispatch(postIntership(formaData)).then((res) => {
          if (res.payload.message === "Message sent successfully") {
            setResponseMessage(res.payload.message);
            setName("");
            setGender("");
            setEmail("");
            setYearsOfEXP("")
            setPhone("");
            setUniversity("");
            setMjOfStudy("");
            setPrevExperince("")
            setYsOfStudy("");
            // setMessage("");
            setFileUploader(null);
            showAndHideMessage();
          } else {
            setErrorMessage(res.payload.message);
          }
        });
      } else {
        window.alert("Please Fill All Fields");
      }
    };
    return (
      <>
        <section className="tf-section section-contact" style={{paddingTop: "140px"}} id="intership-form">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-8 m-auto col-md-7 wow fadeInRight"
                data-wow-delay="400ms"
              >
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={0}
                  data-mobile
                  data-smobile={70}
                />
                <div className="form-contact">
                  <h2>You Can Join Us</h2>
                  <p>
                    Please Enter Your Infromation <span>*</span>
                  </p>
                  <div className="themesflat-contact-form style-2 clearfix padding-top-50">
                    <form
                      id="contactform"
                      className="form-submit contact-form wpcf7-form"
                      onSubmit={handleSubmit}
                    >
                      <span className="wpcf7-form-control-wrap your-name">
                        <input
                          type="text"
                          tabIndex={1}
                          id="name"
                          name="name"
                          className="wpcf7-form-control"
                          placeholder="Full name :"
                          required
                          value={name}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              name: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                name: "Name must be at least 3 characters",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                name: "Name can not be more than 200 characters",
                              });
                            }
                            setName(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.name && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.name}
                        />
                      )}
{/*name*/}
                      <span className="wpcf7-form-control-wrap gender">
                        <p>Gender</p>
                        <div className="p-3 d-flex align-items-center">
                          <input
                            tabIndex={2}
                            type="radio"
                            id="r-b-male"
                            name="gender"
                            className="wpcf7-form-control"
                            required
                            checked={gender === "male" ? "checked" : ""}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            value={"male"}
                          />
                          <label htmlFor="r-b-male" className="px-4">
                            Male
                          </label>
                        </div>
                        <div className="p-3 d-flex align-items-center">
                          <input
                            type="radio"
                            id="r-b-female"
                            name="gender"
                            className="wpcf7-form-control"
                            required
                            checked={gender === "female" ? "checked" : ""}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                            value={"female"}
                          />
                          <label htmlFor="r-b-female" className="px-4">
                            Female
                          </label>
                        </div>
                      </span>
{/*email*/}
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="email"
                          tabIndex={3}
                          id="email"
                          name="email"
                          className="wpcf7-form-control"
                          placeholder="Email Address :"
                          required
                          value={email}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              email: "",
                            });
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            if (!emailRegex.test(e.target.value)) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                email: "Invaid Email Formate",
                              });
                            }
                            if (e.target.value.length < 10) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                email: "Too Short Email",
                              });
                            }
                            setEmail(e.target.value);
                          }}
                        />
                      </span>
{/*phone*/}
                      <span className="wpcf7-form-control-wrap your-phone">
                        <input
                          type="text"
                          tabIndex={4}
                          id="phone"
                          name="phone"
                          className="wpcf7-form-control"
                          placeholder="Phone Number :"
                          required
                          value={phone}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              phone: "",
                            });

                            const phoneRegEx = /^\d{11}$/;

                            if (!phoneRegEx.test(e.target.value)) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                phone: "Invalid Data Input",
                              });
                            }
                            if (e.target.value.length < 11) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                phone:
                                  "Too Short Phone Number Must be 11 Number",
                              });
                            }
                            if (e.target.value.length > 11) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                phone:
                                  "Too Long Phone Number Must be 11 Number",
                              });
                            }
                            setPhone(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.phone && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.phone}
                        />
                      )}
{/*university*/}
                      <span className="wpcf7-form-control-wrap your-university ">
                        <input
                          type="text"
                          tabIndex={5}
                          id="university "
                          name="university "
                          className="wpcf7-form-control"
                          placeholder="University :"
                          required
                          value={university}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              university: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                university:
                                  "University Name must be at least 3 characters",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                university:
                                  "University Name can not be more than 200 characters",
                              });
                            }
                            setUniversity(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.university && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.university}
                        />
                      )}
{/*major*/}
                      <span className="wpcf7-form-control-wrap your-major">
                        <input
                          type="text"
                          tabIndex={6}
                          id="major "
                          name="major "
                          className="wpcf7-form-control"
                          placeholder="Major Of Study :"
                          required
                          value={mjOfStudy}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              mjOfStudy: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                mjOfStudy: "Data must be at least 3 characters",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                mjOfStudy:
                                  "Data can not be more than 200 characters",
                              });
                            }
                            setMjOfStudy(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.mjOfStudy && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.mjOfStudy}
                        />
                      )}
{/*years of study*/}
                      <span className="wpcf7-form-control-wrap your-study-year">
                        <input
                          type="text"
                          tabIndex={7}
                          id="study-year "
                          name="study-year "
                          className="wpcf7-form-control"
                          placeholder="Year  Of Study :"
                          required
                          value={ysOfStudy}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              ysOfStudy: "",
                            });
                            if (e.target.value.length < 3) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                ysOfStudy: "Data must be at least 3 characters",
                              });
                            }
                            if (e.target.value.length > 200) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                ysOfStudy:
                                  "Data can not be more than 200 characters",
                              });
                            }
                            setYsOfStudy(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.ysOfStudy && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.ysOfStudy}
                        />
                      )}
{/*years of exp*/}
                      <span className="wpcf7-form-control-wrap your-email">
                        <input
                          type="text"
                          tabIndex={3}
                          id="email"
                          name="ysOfEx"
                          className="wpcf7-form-control"
                          placeholder="Years Of Experince :"
                          required
                          value={yearsOfEXP}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              yearsOfStudy: "",
                            });
                            if (e.target.value.length < 1) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                yearsOfStudy: "Feild Should not be Zero Or empty!",
                              });
                            }
                            setYearsOfEXP(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.yearsOfEXP && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.yearsOfEXP}
                        />
                      )}
{/*prev exp*/}
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          name="prevExperince"
                          tabIndex={8}
                          cols={40}
                          rows={10}
                          className="wpcf7-form-control wpcf7-textarea"
                          placeholder="Previous Experince :"
                          required
                          // value={message}
                          // onChange={(e) => {
                          //   setFormErrorMessages({
                          //     ...formErrorMessages,
                          //     message: "",
                          //   });
                          //   if (e.target.value.length < 3) {
                          //     setFormErrorMessages({
                          //       ...formErrorMessages,
                          //       message:
                          //         "Message must be at least 3 characters",
                          //     });
                          //   }
                          //   if (e.target.value.length > 200) {
                          //     setFormErrorMessages({
                          //       ...formErrorMessages,
                          //       message:
                          //         "Message can not be more than 2000 characters",
                          //     });
                          //   }
                          //   setMessage(e.target.value);
                          // }}
                          value={prevExperince}
                          onChange={(e) => {
                            setFormErrorMessages({
                              ...formErrorMessages,
                              prevExperince: "",
                            });
                            if (e.target.value.length < 1) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                prevExperince: "Faild Should not be empty!",
                              });
                            }
                            setPrevExperince(e.target.value);
                          }}
                        />
                      </span>
                      {formErrorMessages.prevExperince && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.prevExperince}
                        />
                      )}
{/*CV*/}
                      <span className="wpcf7-form-control-wrap cv-upload">
                        <p>
                          {" "}
                          Upload CV (word,pdf){" "}
                          <span className="text-danger">(max length 5 mg)</span>
                        </p>
                        <input
                          type="file"
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          tabIndex={9}
                          className="wpcf7-form-control form-control-md"
                          required
                          onChange={(e) => {
                            setFileUploader(e.target.files[0]);
                            setFormErrorMessages({
                              ...formErrorMessages,
                              fileMessage: "",
                            });

                            if (e.target.files[0].size > 6000000) {
                              setFormErrorMessages({
                                ...formErrorMessages,
                                fileMessage: "File To Large",
                              });
                            }
                            
                          }}
                        />
                      </span>
                      {formErrorMessages.fileMessage && (
                        <SpanAlert
                          color={"danger"}
                          message={formErrorMessages.fileMessage}
                        />
                      )}

                      <div className="btn-main">
                        {loading ? (
                          <>
                            <button
                              disabled
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                <Spinner animation="border" />
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="submit"
                              className=" submit btn-contact hv-linear border-corner"
                            >
                              <span className="more">
                                Submit Now
                                <span className="icon">
                                  <i className="fas fa-plus" />
                                </span>
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                      <div className="col mt-3">
                        {responseMessage && (
                          <>
                            <Alert variant={"success"}>{responseMessage}</Alert>
                          </>
                        )}
                        {errorMessage && (
                          <>
                            <Alert variant={"danger"}>{errorMessage}</Alert>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      {language === "en" ? <EnglishInternshipForm /> : <ArabicInternshipForm />}
    </>
  );
}
